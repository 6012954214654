<app-heading [title]="'Profile Documents'" [button]="false" [isbreadcrumb]="false" [showToggle]="false"></app-heading>

<div class="flex flex-column gap-4">
    <div class="flex align-items-center justify-content-between">
        <span class="h6">Resume</span>
        @if(!resume){
        <input type="file" #resumeInput style="display: none" (change)="onUploadDocument($event, 'RESUME')">
        <p-button (click)="resumeInput.click()" label="{{isMobile? 'Add': 'Add Resume'}}" rounded="true" size="large"
            icon="pi pi-plus"></p-button>
        }
    </div>

    @if(resume && resume.url){

    <div class="w-full">
        <div class="align-items-center border-bottom-1 flex justify-content-between p-2 surface-border">
            <div class="flex flex-column">
                <span>{{resume.title}}</span>
            </div>
            <div class="flex">
                <p-button pTooltip="Download"
                tooltipPosition="top" (onClick)="downloadDocument(resume)" [rounded]="true" [text]="true" icon="true">
                    <span class="material-symbols-outlined">
                        download
                    </span>
                </p-button>
                <input type="file" #resumeEdit style="display: none" (change)="editDocument($event, 'RESUME', resume)">
                <p-button pTooltip="Edit"
                tooltipPosition="top"  (click)="resumeEdit.click()" [rounded]="true" [text]="true" icon="true">
                    <span class="material-symbols-outlined">
                        edit
                    </span>
                </p-button>
                <p-button pTooltip="Delete"
                tooltipPosition="top" (onClick)="deleteDocment(resume._id,false)" [rounded]="true" [text]="true" icon="true">
                    <span class="red material-symbols-outlined">
                        delete
                    </span>
                </p-button>
            </div>
        </div>
    </div>
    }


    <div class="flex align-items-center justify-content-between">
        <span class="h6">Certification</span>
        @if(!certification){
            <input type="file" #certification style="display: none" (change)="onUploadDocument($event, 'CERTIFICATION')">
        <p-button (click)="certification.click()" label="{{isMobile? 'Add': 'Add Certification'}}" rounded="true" size="large" icon="pi pi-plus"></p-button>
        }
    </div>

    @if(certification && certification.url){

    <div class="w-full">
        <div class="align-items-center border-bottom-1 flex justify-content-between p-2 surface-border">
            <div class="flex flex-column">
                <span>{{certification.title}}</span>
            </div>
            <div class="flex">
                <p-button pTooltip="Download"
                tooltipPosition="top" (onClick)="downloadDocument(certification)" [rounded]="true" [text]="true" icon="true">
                    <span class="material-symbols-outlined">
                        download
                    </span>
                </p-button>
                <input type="file" #certificationEdit style="display: none" (change)="editDocument($event, 'CERTIFICATION',certification)">
                <p-button pTooltip="Edit"
                tooltipPosition="top" (click)="certificationEdit.click()" [rounded]="true" [text]="true" icon="true">
                    <span class="material-symbols-outlined">
                        edit
                    </span>
                </p-button>
                <p-button pTooltip="Delete"
                tooltipPosition="top"  (onClick)="deleteDocment(certification._id,false)"  [rounded]="true" [text]="true" icon="true">
                    <span class="red material-symbols-outlined">
                        delete
                    </span>
                </p-button>
            </div>
        </div>
    </div>
    }

    <div class="flex align-items-center justify-content-between">
        <span class="h6">Additional Documents</span>
        <input type="file" #documents style="display: none" (change)="onUploadDocument($event, 'ADDITIONAL_DOCUMENT')">
        <p-button  (click)="documents.click()" label="{{isMobile? 'Add': 'Add Additional Documents'}}"  rounded="true" size="large" icon="pi pi-plus"></p-button>
    </div>

    @if(additionalDocuments && additionalDocuments.length > 0){
        @for (ad of additionalDocuments; track ad) {
        <div class="w-full">
            <div class="align-items-center border-bottom-1 flex justify-content-between p-2 surface-border">
                <div class="flex flex-column">
                    <span>{{ad.title}}</span>
                </div>
                <div class="flex">
                    <p-button pTooltip="Download"
                    tooltipPosition="top" (onClick)="downloadDocument(ad)" [rounded]="true" [text]="true" icon="true">
                        <span class="material-symbols-outlined">
                            download
                        </span>
                    </p-button>
                    <input type="file" #editDocu style="display: none" (change)="editDocument($event, 'ADDITIONAL_DOCUMENT',ad)">
                    <p-button pTooltip="Edit"
                    tooltipPosition="top" (click)="editDocu.click()" [rounded]="true" [text]="true" icon="true">
                        <span class="material-symbols-outlined">
                            edit
                        </span>
                    </p-button>
                    <p-button pTooltip="Delete"
                    tooltipPosition="top" (onClick)="deleteDocment(ad._id,false)"  [rounded]="true" [text]="true" icon="true">
                        <span class="red material-symbols-outlined">
                            delete
                        </span>
                    </p-button>
                </div>
            </div>
        </div>
        }


    }
</div>

<div class="flex gap-3 mt-3">
    <p-button label="Back" rounded="true" outlined="true" size="large"
        styleClass="white-space-nowrap" (onClick)="onBack()"></p-button>
</div>
