<div class="grid grid-nogutter mb-3">
  <div class="col-12 md:col-6 sm:col-12 sm:mb-2">
    <h1 class="h4 m-0">Change Password</h1>
  </div>
</div>
<form [formGroup]="createForm">
  <div class="grid grid-nogutter">
    <div class="col-12 lg:col-4 xl:lg:col-4 mb-3">
      <p-floatLabel>
        <p-password styleClass="w-full" formControlName="currentPassword" [toggleMask]="true" [feedback]="false" />
        <label for="currentPassword">Enter Current Password<span class="required">*</span></label>
      </p-floatLabel>
    </div>
    <div class="col-8"></div>
    <div class="col-12 lg:col-4 xl:lg:col-4 mb-3">
      <p-floatLabel>
        <p-password styleClass="w-full" formControlName="newPassword" [toggleMask]="true" [feedback]="false" />
        <label for="newPassword">Enter New Password<span class="required">*</span></label>
      </p-floatLabel>
    </div>
    <div class="col-8"></div>

    <div class="col-12 lg:col-4 xl:lg:col-4 mb-3">
      <p-floatLabel>
        <p-password styleClass="w-full" formControlName="confirmPassword" [toggleMask]="true" [feedback]="false" />
        <label for="confirmPassword">Confirm New Password<span class="required">*</span></label>
      </p-floatLabel>
    </div>
    <div class="col-8"></div>
    <div>
      <p-button class="col-4 lg:col-4 xl:lg:col-4" styleClass="freshgreen-background" (click)="onSubmit()"
        [rounded]="true" [disabled]="!createForm.valid">
        Update Password
      </p-button>
    </div>
  </div>
</form>