<form [formGroup]="approvalForm">
    <div class="p-grid">
      <!-- Title -->
      <div class="p-col-12">
        <span class="text-primary text-xl">
          Approve Reviewer {{ selectedCredential.type | lowercase }}
        </span>
      </div>
  
      <!-- Approved By -->
      <div class="p-col-6">
        <label for="approvedByName">Approved by</label>
        <input pInputText formControlName="approvedByName" />
      </div>
  
      <!-- File Upload -->
      <div class="p-col-6">
        <label for="documentUpload">Audit Document</label>
        <p-fileUpload mode="basic" name="file" (onSelect)="onFileSelect($event)"
          accept="image/*,application/pdf,.doc,.docx,.xls,.xlsx,.txt"></p-fileUpload>
      </div>
  
      <!-- Audit Trail -->
      <div class="p-col-12">
        <label for="auditTrialInformation">Audit Trail Information</label>
        <textarea  pInputTextarea [autoResize]="true" id="auditTrialInformation" formControlName="auditTrialInformation" ></textarea>
      </div>
  
      <!-- Action Buttons -->
      <div class="p-col-12 p-text-right">
        <p-button label="Refuse" severity="danger" (click)="confirmApproval(true)"></p-button>
        &nbsp;
        <p-button label="Approve" severity="success" (click)="confirmApproval(false)"></p-button>
      </div>
    </div>
  </form>
  

  <!-- PrimeNG Confirmation Dialog -->
<p-confirmDialog></p-confirmDialog>