import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, lastValueFrom } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { constants, UserRoles } from '../constants/constants';
import { ReviewerService } from '../services/reviewer.service';
import { StorageService } from '../services/storage.service';
import { UserClientVm } from '../../store/user/user.interface';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class OnboardingStatusGuard implements CanActivate {

    
  userCurrentTenant: any = null;
  constructor(private authService: AuthService, private router: Router, private storageService: StorageService, private userService: UserService) {
    this.userCurrentTenant = this.storageService.getTenant();
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    const user = await lastValueFrom(this.authService.getSessionUser());
    let userCurrentTenant = null;
    if(user && user.tenants && user.tenants.length && this.userCurrentTenant){
        userCurrentTenant = user.tenants.find((tenant: any) => {
            return this.userCurrentTenant.firm._id === tenant.firm._id;
        })
    }
    if(userCurrentTenant && userCurrentTenant.reviewerStatus === constants.userVerificationStatus.incomplete){
        return true;
    } else {
      this.router.navigateByUrl('/reviewer')
      return false;
    }
  }
}
