<div class="grid grid-nogutter">
    <div class="p-0 col-12">
        <p class="m-0">Double check your information. Any information has already been saved and updated.</p>
    </div>
    <div class="col-12 p-0 my-3 flex align-items-center">
        <span class="font-medium">Managed by {{reviews?.owner?.firstName}} {{reviews?.owner?.lastName}}</span>
        <p-button [rounded]="true" [text]="true" icon="true" (click)="changeTabIndex(0)">
            <span class="material-symbols-outlined text-primary-900">
                edit
            </span>
        </p-button>
    </div>
    <div class="col-12">
        <div class="grid">
            <div class="col-12 md:col-12 sm:col-12">
                <div class="last-project p-0 border-round-2xl bg-white shadow-3 mb-4">
                    <div
                        class="card-head flex align-items-center justify-content-between py-2 px-3 border-round-2xl border-noround-bottom bg-indigo-50">
                        @if(reviews?.docuSignTemplates?.length || reviews?.documents?.length){
                            <span class="text-lg font-medium text-primary-900">Required Documents</span>
                            <p-button [link]="true" [text]="true" styleClass="text-primary-900 p-0 gap-2"
                                (click)="changeTabIndex(1)">
                                <span class="material-symbols-outlined text-primary-900"> upload </span>
                                UPLOAD
                            </p-button>
                        } @else {
                            <span class="text-lg font-medium text-primary-900">No documents required for this project</span>
                        }
                    </div>
                    @if(!reviews?.firm?.isDocusignEnabled){
                    <div class="card-body px-3 py-2">
                        <div class="wrap-details">
                            <ul class="filename m-0 h-6rem overflow-auto flex-row gap-2">
                                @if(reviews?.documents?.length){
                                @for(document of reviews?.documents; track document ){
                                <li class="flex align-items-center justify-content-between">
                                    <span class="text-sm font-medium">{{document.title}}</span>
                                    <div class="actions flex align-items-center gap-2">
                                        <p-button [rounded]="true" [text]="true" icon="true"
                                            (click)="changeTabIndex(1)">
                                            <span class="material-symbols-outlined text-primary-900">
                                                edit
                                            </span>
                                        </p-button>
                                        <a target="_self" href="{{document.url}}">
                                            <span class="material-symbols-outlined text-primary-900">
                                                download
                                            </span>
                                        </a>
                                    </div>
                                </li>
                                }
                                }
                            </ul>
                        </div>
                    </div>
                    }
                    @if(reviews?.firm?.isDocusignEnabled){
                    <div class="card-body px-3 py-2">
                        <div class="wrap-details">
                            <ul class="filename m-0 h-6rem overflow-auto flex-row gap-2">
                                @if(reviews?.docuSignTemplates?.length){
                                @for(template of reviews?.docuSignTemplates; track template ){
                                @for(document of template?.documents; track document ){
                                <li class="flex align-items-center justify-content-between">
                                    <span class="text-sm font-medium">{{document.name}}</span>
                                    <div class="actions flex align-items-center gap-2">
                                        <p-button [rounded]="true" [text]="true" icon="true"
                                            (click)="viewDocument(template.templateId,document.documentId,document.name)">
                                            <span class="material-symbols-outlined text-primary-900">
                                                download
                                            </span>
                                        </p-button>
                                    </div>
                                </li>
                                }
                                }
                                }
                            </ul>
                        </div>
                    </div>
                    }
                </div>
            </div>
            <!-- <div class="col-12 md:col-6 sm:col-12">
                <div class="last-project p-0 border-round-2xl bg-white shadow-3 mb-4">
                    <div
                        class="card-head flex align-items-center justify-content-between py-2 px-3 border-round-2xl border-noround-bottom bg-indigo-50">
                        <span class="text-lg font-medium text-primary-900">Location</span>

                    </div>
                    <div class="card-body px-3 py-2">
                        <div class="wrap-details">
                            <ul class="filename m-0 h-6rem overflow-auto flex-row gap-2">
                                <li class="flex justify-content-between">
                                    <div class="flex flex-column gap-2">
                                        <span class="text-sm font-medium">Point of Contact</span>
                                        @for(role of roles; track role){
                                            @if(role.status !== roleStatus.archive){
                                                @if(role?.reviewCenterLocation?.pointOfContact !== ''){
                                                    <span class="text-sm font-normal my-1">{{role.roleType}} - {{role?.reviewCenterLocation?.pointOfContact}}</span>
                                                }
                                                @else{
                                                    @if(role?.reviewCenterLocation?.instructions){
                                                        <span class="text-sm font-normal my-1">{{role.roleType}}</span>
                                                    }
                                                }
                                            }
                                        }
                                    </div>
                                    <div class="flex flex-column gap-2">
                                        <span class="text-sm font-medium">Instruction For Candidates</span>
                                        @for(role of roles; track role){
                                            @if(role.status !== roleStatus.archive){
                                                @if(role?.reviewCenterLocation?.pointOfContact !== '' || role?.reviewCenterLocation?.instructions){
                                                    <span class="text-sm font-normal my-1">
                                                        {{role?.reviewCenterLocation?.instructions || '-'}}</span>
                                                }
                                            }
                                        }
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
    <div class="col-12 p-0">
        <p-table [value]="roles" styleClass="p-datatable-sm p-datatable-sm--normal" dataKey="id"
            [tableStyle]="{ 'min-width': '60rem' }" [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)"
            (onRowCollapse)="onRowCollapse($event)">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="name">
                        Role
                        <p-sortIcon field="name" />
                    </th>
                    <th pSortableColumn="code">
                        Start Date/Time
                        <p-sortIcon field="code" />
                    </th>
                    <th style="width: 8%">Action</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" class="border-0" let-role let-expanded="expanded">
                <tr>
                    <td>{{ role.roleType }}</td>
                    <td>{{ role.startAt | date: "MM-dd-yyyy h:mm a" : 'UTC'}}</td>
                    <td>
                        <div class="wrap-actions flex align-items-center justify-content-end gap-2">
                            @if(role.status !== 'ARCHIVE'){
                                <p-button [rounded]="true" [text]="true" icon="true" (click)="gotoRoleEdit(2, role._id)">
                                    <span class="material-symbols-outlined text-primary-900">
                                        edit
                                    </span>
                                </p-button>
                            }
                            <p-button type="button" pRipple [pRowToggler]="role" [text]="true" [rounded]="true"
                                [plain]="true" [icon]="
                    expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
                    " class="row-toggler" />
                        </div>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="rowexpansion" let-role>
                <tr>
                    <td colspan="4" class="p-0">
                        <div class="col-12 md:col-12 sm:col-12">
                            <div class="last-project p-0 border-round-2xl bg-white shadow-3 mb-4">
                                <div
                                    class="card-head flex align-items-center justify-content-between py-2 px-3 border-round-2xl border-noround-bottom bg-indigo-50">
                                    <span class="text-lg font-medium text-primary-900">Location</span>

                                </div>
                                <div class="card-body px-3 py-2">
                                    <div class="wrap-details">
                                        <ul class="filename m-0 h-6rem overflow-auto flex-row gap-2">
                                            <li class="flex justify-content-between">
                                                <div class="flex flex-column gap-2">
                                                    <span class="text-sm font-medium">Point of Contact</span>
                                                    @for(r of getselectedRoles(role); track r){
                                                    @if(r.status !== roleStatus.archive){
                                                    @if(r?.reviewCenterLocation?.pointOfContact !== ''){
                                                    <span class="text-sm font-normal my-1">{{r.roleType}} -
                                                        {{r?.reviewCenterLocation?.pointOfContact}}</span>
                                                    }
                                                    @else{
                                                    @if(r?.reviewCenterLocation?.instructions){
                                                    <span class="text-sm font-normal my-1">{{r.roleType}}</span>
                                                    }
                                                    }
                                                    }
                                                    }
                                                </div>
                                                <div class="flex flex-column gap-2">
                                                    <span class="text-sm font-medium">Instruction For Candidates</span>
                                                    @for(r of getselectedRoles(role); track r){
                                                    @if(r.status !== roleStatus.archive){
                                                    @if(r?.reviewCenterLocation?.pointOfContact !== '' ||
                                                    r?.reviewCenterLocation?.instructions){
                                                    <span class="text-sm font-normal my-1">
                                                        {{r?.reviewCenterLocation?.instructions || '-'}}</span>
                                                    }
                                                    }
                                                    }
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="sub-details surface-100 py-3 px-4">
                            <div class="grid grid-nogutter">
                                <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                    <span class="text-base block font-medium mt-3">Project Type</span>
                                </div>
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                    @for(job of roleDetailsDisplay?.jobType; track job){
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                        {{ job }}
                                    </p>
                                    }
                                </div>
                                <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                    <span class="text-base block font-medium mt-3">Education</span>
                                </div>
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                    <span class="text-primary mt-2 block text-xs">Under Graduate</span>
                                    @if(roleDetailsDisplay?.underGrad?.length){
                                    @for(education of roleDetailsDisplay?.underGrad; track education){
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                        {{education.type}}-{{education.major}}</p>
                                    }
                                    }
                                    @else{
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                                    }
                                </div>
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                    <span class="text-primary mt-2 block text-xs">Graduate</span>
                                    @if(roleDetailsDisplay?.graduate?.length){
                                    @for(education of roleDetailsDisplay?.graduate; track education){
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                        {{education.type}}-{{education.major}}</p>
                                    }
                                    }
                                    @else{
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                                    }
                                </div>
                                <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                    <span class="text-base block font-medium mt-3">Skills and Experience</span>
                                </div>
                                @if(roleDetailsDisplay?.skillsAndExperience?.length){
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                    @for(skill of roleDetailsDisplay?.skillsAndExperience; track skill){
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">{{skill}}</p>
                                    }
                                </div>
                                }
                                @else{
                                <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                                }
                                <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                    <span class="text-base block font-medium mt-3">Credentials</span>
                                </div>
                                @if(roleDetailsDisplay?.credential?.length){
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                    @for(cred of roleDetailsDisplay?.credential; track cred){
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">{{cred.val}}</p>
                                    }
                                </div>
                                }
                                @else{
                                <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                                }
                                <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                    <span class="text-base block font-medium mt-3">Timeline and Schedule</span>
                                </div>
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                    <span class="text-primary mt-2 block text-xs">Starts On</span>
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                        {{ role.startAt | date : "MMM dd YYYY" }}
                                    </p>
                                </div>
                                @if(role.workSchedule.schedule.weekDays.willWork){
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                    <span class="text-primary mt-2 block text-xs">Monday - Friday</span>
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                        Start
                                        {{
                                        helperService.formatTime(role.workSchedule.schedule.weekDays.time.start)
                                        }}
                                    </p>
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                        End
                                        {{
                                        helperService.formatTime(role.workSchedule.schedule.weekDays.time.end)
                                        }}
                                    </p>
                                </div>
                                }
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                    <span class="text-primary mt-2 block text-xs">Expected Duration</span>
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                        {{ role.workSchedule.duration }}
                                    </p>
                                </div>
                                @if(role.workSchedule.schedule.saturday.willWork){
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                    <span class="text-primary mt-2 block text-xs">Saturday</span>
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                        Start
                                        {{
                                        helperService.formatTime(role.workSchedule.schedule.saturday.time.start)
                                        }}
                                    </p>
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                        End
                                        {{
                                        helperService.formatTime(role.workSchedule.schedule.saturday.time.end)
                                        }}
                                    </p>
                                </div>
                                }
                                @if(role.workSchedule.workHours){
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                    <span class="text-primary mt-2 block text-xs">Expected hours</span>
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                        {{ role.workSchedule.workHours }}
                                    </p>
                                </div>
                                }
                                @if(role.workSchedule.schedule.sunday.willWork){
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                    <span class="text-primary mt-2 block text-xs">Sunday</span>
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                        Start
                                        {{
                                        helperService.formatTime(role.workSchedule.schedule.sunday.time.start)
                                        }}
                                    </p>
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                        End
                                        {{ helperService.formatTime(role.workSchedule.schedule.sunday.time.end) }}
                                    </p>
                                </div>
                                }
                                <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                    <span class="text-base block font-medium mt-3">Screening Questions</span>
                                </div>
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                    <span class="text-primary mt-2 block text-xs">Case Captions</span>
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                        {{captionName(project?.caption)}}</p>
                                </div>
                                @if(role?.hasConflict ){
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                    <span class="text-primary mt-2 block text-xs">Conflicts Checked Against</span>
                                    @if(role?.scanForConflicts?.fullCompanyNames){
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                        Full Company Names
                                    </p>
                                    } @if(role?.scanForConflicts?.specificQuestions){
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                        Questions
                                    </p>
                                    }
                                </div>
                                @if(role?.scanForConflicts?.specificQuestions){
                                @if(role?.conflictQuestions?.length){
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                    <span class="text-primary mt-2 block text-xs">Questions</span>
                                    <ul class="m-0 list-none p-0 text-black-alpha-70 text-xs">
                                        @for(question of role.conflictQuestions; track question){
                                        <li>Q{{ $index + 1 }} - {{ question?.title }}</li>
                                        }
                                    </ul>
                                </div>
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                    <span class="text-primary mt-2 block text-xs">Answers</span>
                                    <ul class="m-0 list-none p-0 text-black-alpha-70 text-xs">
                                        @for(question of role.conflictQuestions; track question){
                                        <li>A{{ $index + 1 }} - {{ question?.answer? 'Yes': 'No' }}</li>
                                        }
                                    </ul>
                                </div>
                                }
                                }
                                } @else {
                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                    <span class="text-primary mt-2 block text-xs">Conflict Questions</span>
                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">No conflict questions</p>
                                </div>
                                }
                            </div>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>