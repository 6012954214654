import { Component } from '@angular/core';
import { UserService } from '../../../../../../../shared/services/user.service';
import { PeopleService } from '../../../../../../../shared/services/people.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '../../../../../../../shared/services/toast.service';
import { saveAs } from 'file-saver';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { environment } from '../../../../../../../../environments/environment';
import { constants } from '../../../../../../../shared/constants/constants';
import { ConfigurationService } from '../../../../../../../shared/services/configuration.service';

@Component({
  selector: 'app-bulk-invite',
  standalone: true,
  imports: [
    ButtonModule,
    TableModule
  ],
  templateUrl: './bulk-invite.component.html',
  styleUrl: './bulk-invite.component.scss'
})
export class BulkInviteComponent {

  user: any;
  firmId: string | undefined;
  csvPaginatedData: any[] = [];
  csvOrignalData: any[] = [];
  duplicateCsvUsers: string[] = [];
  pagination = {
    numPerPage: 25,
    currentPage: 1,
    maxSize: 25
  };
  csvData: any[] = [];
  expectedHeaders = ['First Name', 'Last Name', 'Email', 'Phone Number', 'Job type 1'];
  file: File | null = null;
  title = environment.title;
  jobTypeHeaders: Array<string> = [];
  isPwcEnabled = false;

  constructor(
    private ref: DynamicDialogRef,
    private userService: UserService,
    private peopleService: PeopleService,
    private toastService: ToastService,
    private configurationService: ConfigurationService
  ) {
   }

  ngOnInit(): void {
    this.user = this.userService.getSessionUser();
    this.firmId = this.user?.firm?._id;
    this.isPwcEnabled = this.configurationService.getPwcFlag();
    if(this.isPwcEnabled){
      this.expectedHeaders = [...this.expectedHeaders, 'PWC Email', 'Primary Pay Rate', 'Secondary Pay Rate', 'Default Rating', 'Ranking']
    }
  }

  uploadCSV(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      this.file = input.files[0];
      this.readFile(this.file);
    }
  }

  bulkInviteCandidateSubmit(): void {
    const users = this.csvOrignalData.filter(user => !this.duplicateCsvUsers.includes(user.email));
    const body = {
      firmId: this.firmId,
      users: users,
      isBulk: true
    };

    this.peopleService.inviteCandidates(body).subscribe({
      next: (response) => {
        this.ref.close({
          isSuccess: true,
          users: response.map((user: any) => {
            const selectedUser = users.find((csvUser: any) => user.email.toLowerCase() === csvUser.email.toLowerCase());
            user = {...user, pwcEmail: selectedUser.pwcEmail, primaryPayRate: selectedUser.primaryPayRate, secondaryPayRate: selectedUser.secondaryPayRate, rating: selectedUser.rating };
            if(selectedUser && selectedUser.jobTypes.length){
              return {...user, jobTypes: selectedUser.jobTypes};
            }
            return user;
          })
        });
      },
      error: (error) => {
        this.toastService.showError(error?.data?.message ?? 'Error while requesting.');
        this.ref.close(null);
      }
    });
  }

  parseCSV(csvText: string): any[] {
    const lines = csvText.split(/\r?\n|\r/);
  
    const headers = this.parseCSVLine(lines[0]);
  
    this.jobTypeHeaders = headers.filter(header => header.toLowerCase().startsWith("Job Type".toLowerCase()));
  
    let staticHeaders = ["First Name", "Last Name", "Email", "Phone Number"];
    if(this.isPwcEnabled){
      staticHeaders = [...staticHeaders, "PWC Email", "Primary Pay Rate"];
    }
    for (const header of staticHeaders) {
      if (!headers.includes(header)) {
        this.toastService.showError('Required headers are missing');
        return [];
      }
    }
  
    const result = this.getResult(lines, headers);
  
    this.csvOrignalData = [];
    for (const record of result) {
      if (!record["First Name"] || !record["Last Name"] || !record["Email"]) {
        this.toastService.showError('Required fields are missing');
        this.csvOrignalData = [];
        break;
      }
      if(this.isPwcEnabled && (!record["PWC Email"] || !record["Primary Pay Rate"])){
        this.toastService.showError('PWC Required fields are missing');
        this.csvOrignalData = [];
        break;
      }

      if(!record['Email'].match(constants.emailRegex)){
        this.toastService.showError('Invalid Email');
        this.csvOrignalData = [];
        break;
      }

      if(record['PWC Email'] && !record['PWC Email'].match(constants.emailRegex)){
        this.toastService.showError('Invalid PWC Email');
        this.csvOrignalData = [];
        break;
      }
      
      const numberColumns = ["Phone Number"];
      const errorColumn = this.validateNumberColumns(record, numberColumns);
      if(errorColumn != ''){
        this.toastService.showError(`${errorColumn} Should be number`);
        this.csvOrignalData = [];
        break;
      }

      if(this.isPwcEnabled){
        const pwcNumberColumns = ["Primary Pay Rate", "Secondary Pay Rate", "Default Rating"];
        const pwcErrorColumn = this.validateNumberColumns(record, pwcNumberColumns);
        if(errorColumn != ''){
          this.toastService.showError(`${pwcErrorColumn} Should be number`);
          this.csvOrignalData = [];
          break;
        }  
      }

      if (record["Phone Number"] && record["Phone Number"].toString().length > 10) {
        this.toastService.showError('One of the Phone Numbers is more than the allowed length (10 digits)');
        this.csvOrignalData = [];
        break;
      }

      if (this.isPwcEnabled && record["Primary Pay Rate"] && (Number(record["Primary Pay Rate"]) > 100 || Number(record["Primary Pay Rate"]) < 1)) {
        this.toastService.showError('Primary Pay Rate Should be less than 100 and greater than 1');
        this.csvOrignalData = [];
        break;
      }

      if (this.isPwcEnabled && record["Secondary Pay Rate"] && (Number(record["Secondary Pay Rate"]) > 100 || Number(record["Secondary Pay Rate"]) < 1)) {
        this.toastService.showError('Secondary Pay Rate Should be less than 100 and greater than 1');
        this.csvOrignalData = [];
        break;
      }

      if (this.isPwcEnabled && record["Default Rating"] && (Number(record["Default Rating"]) > 5 || Number(record["Default Rating"]) < 0)) {
        this.toastService.showError('Default Rating Should be less than 5 and greater than 0');
        this.csvOrignalData = [];
        break;
      }

      if (this.isPwcEnabled && record["Ranking"] && !Object.values(constants.userRankings).includes(record['Ranking'])) {
        this.toastService.showError('Ranking Should be PL/TL / QC / 1.5L / 1L / 0L/0.5L');
        this.csvOrignalData = [];
        break;
      }
  
      const jobTypes = this.getJobTypes(record);
  
      if (this.csvOrignalData.find((data: any) => data.email === record["Email"]) && jobTypes.length) {
        this.csvOrignalData = this.csvOrignalData.map((csvData: any) => {
          if(record["Email"] === csvData.email){
            return {...csvData, jobTypes}
          }
          return csvData;
        })
      } else if(!(this.csvOrignalData.find((data: any) => data.email === record["Email"]))){
        let invitePayload: any = {
          firstName: record["First Name"],
          lastName: record["Last Name"],
          email: record["Email"],
          phone: record["Phone Number"],
          jobTypes: jobTypes,
        }

        if(this.isPwcEnabled){
          invitePayload.pwcEmail = record["PWC Email"];
          invitePayload.primaryPayRate = record["Primary Pay Rate"];
          invitePayload.secondaryPayRate = record["Secondary Pay Rate"] || 1;
          invitePayload.rating = record["Default Rating"] || 0;
        }
        
        this.csvOrignalData.push(invitePayload);
      }
    }

    return this.csvOrignalData;
  }

  validateNumberColumns(row: any, cols: string[]){
    let errorColumn = '';
    for (const col of cols) {
      if (row[col] && Number.isNaN(Number(row[col]))) {
        errorColumn = col;
        break;
      }
    }
    return errorColumn;
  }

  getJobTypes(record: any){
    const jobTypes: string[] = [];
    for (const jobHeader of this.jobTypeHeaders) {
      if (record[jobHeader]) {
        jobTypes.push(record[jobHeader]);
      }
    }
    return jobTypes;
  }

  getResult(lines: any, headers: any){
    const result = [];
    for (let i = 1; i < lines.length; i++) {
      if (!lines[i].trim()) {
        continue;
      }
      result.push(this.getObj(headers, lines[i]));      
    }
    return result;
  }

  getObj(headers: any, line: any){
    const obj: any = {};
    const currentline = this.parseCSVLine(line);
    for (let j = 0; j < headers.length; j++) {
      obj[headers[j]] = currentline[j];
    }
    return obj;
  }

  parseCSVLine(line: string): string[] {
    const result = [];
    let currentField = '';
    let insideQuotes = false;

    for (let i = 0; i < line.length; i++) {
      const char = line[i];

      if (char === '"') {
        if (insideQuotes && line[i + 1] === '"') {
          currentField += '"';
          i++;
        } else {
          insideQuotes = !insideQuotes;
        }
      } else if (char === ',' && !insideQuotes) {
        result.push(currentField.trim());
        currentField = '';
      } else {
        currentField += char;
      }
    }
    result.push(currentField.trim());

    return result;
  }

  readFile(file: File): void {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => {
      if (reader.result) {
        this.csvData = this.parseCSV(reader.result as string);
        this.checkDuplication(this.csvData);
        this.csvPaginatedData = this.configureCsvPaginatedData();
      }
    };
  }

  checkDuplication(data: any[]): void {
    const emails = data.map(e => e.email);
    this.peopleService.checkEmailExistence(emails).subscribe(response => {
      if (response && response.data) {
        this.duplicateCsvUsers = response.data;
      }
    });
  }

  configureCsvPaginatedData(): any[] {
    const currentPage = this.pagination.currentPage - 1;
    const perPage = this.pagination.numPerPage;
    return this.csvOrignalData.slice(currentPage * perPage, this.pagination.currentPage * perPage);
  }

  downloadCSVTemplate(): void {
    const csv: string[] = [];
    const headers = this.expectedHeaders.join(',') + '\n';
    csv.push(headers);

    let users: any = [
      { firstName: 'Test1', lastName: 'User', email: 'test1.user@yopmail.com', phoneNo: '1312313123', 'Job type 1': 'Job type value 1'},
      { firstName: 'Test2', lastName: 'User', email: 'test2.user@yopmail.com', phoneNo: '1231212312', 'Job type 1': 'Job type value 2'}
    ];

    if(this.isPwcEnabled){
      users[0] =  { ...users[0], pwcEmail: 'test1.userpwc@yopmail.com', primaryPayRate: 15, secondaryPayRate: 10, rating: 3, ranking: 'QC'};
      users[1] =  { ...users[1], pwcEmail: 'test2.userpwc@yopmail.com', primaryPayRate: 15, secondaryPayRate: 10, rating: 3, ranking: 'QC'};
    }

    users.forEach((user: any) => {
      let cols: any = [
        user.firstName,
        user.lastName,
        user.email,
        user.phoneNo,
        user['Job type 1'], 
      ];

      if(this.isPwcEnabled){
        cols = [
          ...cols,
          user.pwcEmail,
          user.primaryPayRate,
          user.secondaryPayRate,
          user.rating,
          user.ranking
        ]
      }

      cols = cols.join(',') + '\n';

      csv.push(cols);
    });

    const blob = new Blob(csv, { type: "text/plain;charset=utf-8" });
    saveAs(blob, "sampleBulkUpload.csv");
  }

  pageChanged(): void {
    this.csvPaginatedData = this.configureCsvPaginatedData();
  }

  checkIfExist(email: string): boolean {
    return this.duplicateCsvUsers.includes(email);
  }

  cancel(): void {
    this.ref.close();
  }
}
