import { Component, OnInit } from '@angular/core';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogModule,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import moment from 'moment';
import { constants } from '../../../../../../../../shared/constants/constants';
import { CalendarModule } from 'primeng/calendar';
import { DividerModule } from 'primeng/divider';
import { RolesService } from '../../../../../../../../shared/services/roles.service';
import { ToastService } from '../../../../../../../../shared/services/toast.service';

@Component({
  selector: 'offer-confirmation-modal',
  providers: [DialogService],
  standalone: true,
  imports: [
    FloatLabelModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    InputTextModule,
    CommonModule,
    ReactiveFormsModule,
    DynamicDialogModule,
    DropdownModule,
    CalendarModule,
    DividerModule
  ],
  templateUrl: './offer-confirmation-modal.component.html',
})
export class OfferConfirmationModalComponent implements OnInit {
  createForm!: FormGroup;
  reviewerName = '';
  reviewerId = '';
  currentUTCDate: any = moment.utc().format('YYYY-MM-DDTHH:mm');
  defaultRoleStart = constants.defaultRoleStartTime;
  formaterUTCDate: any = null;
  minDate: Date | null;
  isStartedRole:boolean = false;
  message!: string;
  role!: any;
  constructor(
    public ref: DynamicDialogRef,
    private fb: FormBuilder,
    public dialogService: DialogService,
    public config: DynamicDialogConfig,
    private roleService: RolesService,
    private toastService: ToastService
  ) {
    this.minDate = moment(new Date()).utc().toDate();
  }

  ngOnInit() {
    this.currentUTCDate = new Date(moment.utc().format('YYYY-MM-DDTHH:mm'));
    this.formaterUTCDate = new Date(this.currentUTCDate);
    this.formaterUTCDate.setHours(this.defaultRoleStart.hour);
    this.formaterUTCDate.setMinutes(this.defaultRoleStart.minute);
    this.reviewerId = this.config?.data?._id;
    this.reviewerName = this.config?.data?.fullName || this.config?.data?.firstName && this.config?.data?.lastName ? `${this.config?.data?.firstName} ${this.config?.data?.lastName}` : '';
    this.createForm = this.fb.group({
      positionStarted: [this.formaterUTCDate],
      reviewerId: [this.reviewerId],
      roleId: [this.config?.data?.roleId],
      startTime: [this.getDateTimeObjWithTime(9, 0)]
    });
    this.isStartedRole = this.config?.data?.isStarted;
    if (this.isStartedRole) {
      this.createForm.get('positionStarted')?.setValue(null);
      this.createForm.get('positionStarted')?.setValidators(Validators.required);
      this.createForm.get('positionStarted')?.updateValueAndValidity({emitEvent: false});
    } else {
      this.createForm.get('positionStarted')?.clearValidators();
      this.createForm.get('positionStarted')?.updateValueAndValidity({emitEvent: false});
    }    
    this.message = this.config?.data?.message;
  }


  getDateTimeObjWithTime(hours: number, mins: number) {
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(mins);
    return date;
  }

  async closeDialog(reviewerId = false) {
    if(reviewerId){
      this.role = await this.roleService.getRoleByProjectAndRoleId(this.config.data.projectId, this.config.data.roleId);
      const startDate = moment(this.createForm.controls['positionStarted'].getRawValue())
      const startTime = moment(this.createForm.controls['startTime'].getRawValue())
      
      const position = moment.tz(this.role.timezone?.split('[')[0] || 'UTC')
      position.set({
        year: startDate.year(),
        month: startDate.month(),
        date: startDate.date(),
        hour: startTime.hour(),
        minute: startTime.minute()
      });

      if(this.createForm.valid){
        this.ref.close({
          ...this.createForm.value,
          positionStarted: position.utc().toString()
        });
        return;
      }
    } else {
      this.ref.close();
    }
  }

  showMsg(){
    if(this.createForm.value.positionStarted){
      return moment(this.createForm.controls['positionStarted'].getRawValue()).isAfter(moment.utc().format('YYYY-MM-DDTHH:mm'));
    }
    return false;
  }
}
