import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TableModule , TableRowCollapseEvent, TableRowExpandEvent } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { ProjectService } from '../../../../../../shared/services/project.service';
import { CommonModule } from '@angular/common';
import { CheckboxModule } from 'primeng/checkbox';
import { TabViewModule } from 'primeng/tabview';
import { FireReviewerConfirmation } from '../../project-listing/modals/fire-reviewer/fire-reviewer.component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FireReviewerPayload } from '../../project.interface';
import { ToastService } from '../../../../../../shared/services/toast.service';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ChipModule } from 'primeng/chip';
import { InputTextModule } from 'primeng/inputtext';
import { FloatLabelModule } from 'primeng/floatlabel';
import { MultiSelectModule } from 'primeng/multiselect';
import { ReviewerDetailDialogService } from '../../../../../../shared/services/reviewer-detail-dialog.service';
import { TooltipModule } from 'primeng/tooltip';
import { RateFreelancersComponent } from '../../project-listing/modals/rate-freelancers/rate-freelancers.component';
import { FirmService } from '../../../../../../shared/services/firm.service';
import { UserClientVm } from '../../../../../../store/user/user.interface';
import { UserService } from '../../../../../../shared/services/user.service';
import { NotesService } from '../../../../../../shared/services/notes.service';
import { MessageComponentService } from '../../../message/message.component.service';
import { SendMailModalComponent } from '../modals/send-mail/send-mail.component';
import { constants } from '../../../../../../shared/constants/constants';
import { ViewReviewerDocumentsModalComponent } from '../modals/view-reviewer-documents-modal/view-reviewer-documents-modal.component';
import { OfferConfirmationModalComponent } from '../../create/offers/modals/offer-confirmation-modal/offer-confirmation-modal.component';
import { OfferService } from '../../../../../../shared/services/offers.service';
import { Offers } from '../../../../../../shared/interfaces/offers.interface';
import { FirebaseMessagingService } from '../../../../../../shared/services/firebase-messaging.service';
import moment from 'moment';
import { RoleService } from '../../../../../../shared/services/role.service';
import { CalendarModule } from 'primeng/calendar';
import { RateBulkFreelancersComponent } from '../../project-listing/modals/rate-bulk-freelancers/rate-bulk-freelancers.component';
import {PresumptiveApprovalModalComponent} from '../modals/presumptive-approval/presumptive-approval.component';
import { EmitterVisitorContext } from '@angular/compiler';
import { EmittersService } from '../../../../../../shared/services/emitters.service';
import { lastValueFrom } from 'rxjs';
import { DocumentService } from '../../../../../../shared/services/document-service';

@Component({
  selector: 'app-staff-tab',
  standalone: true,
  imports: [TableModule, ButtonModule, IconFieldModule, InputIconModule, CommonModule, CheckboxModule, TabViewModule, DialogModule, DropdownModule, 
    FormsModule, ChipModule, InputTextModule, FloatLabelModule, MultiSelectModule, TooltipModule, CalendarModule],
  providers: [DialogService],
  templateUrl: './staff-tab.component.html',
  styleUrl: './staff-tab.component.scss'
})
export class StaffTabComponent implements OnInit{

  @Input() projectId!: any;
  @Input() projectStaffList!: any;
  @Input() projectBundle!: any;
  @Output() 
  updateAndReInitializeStaff: EventEmitter<void> = new EventEmitter();
  @Input()
  projectDetails! : any;
  expandedRows = {};
  checked: boolean = false;
  visible: boolean = false;
  emailType = constants.emailType;
  projectPositionsListed: number = 0;
  @Input() projectPositionsFilled: number = 0;
  ref: DynamicDialogRef | undefined;
  user!: UserClientVm;
  firmId!: string;
  visibleDeleteModal = false;
  selectedDeleteRole: any;
  unReadDiscussions: Array<any> = [];
  isUnreadMessagesExist = false;
  restartDate = moment(new Date()).utc().toDate();
  restartTime = this.getDateTimeObjWithTime(9, 0) ;
  deleteRoleComment = '';
  roleStatus = constants.roleStatus;
  roleMinDate = moment(new Date()).utc().toDate();
  roleMaxDate = moment(new Date()).utc().add(constants.daysDiffrenceBetweenDate, 'days').toDate();
  
  constructor(
    private projectService: ProjectService, 
    public dialogService: DialogService,
    private toastService: ToastService, 
    private reviewerDetailDialogService: ReviewerDetailDialogService,
    private firmService: FirmService,
    private userService: UserService,
    private notesService: NotesService,
    private messageCompoenentService: MessageComponentService,
    private offerService: OfferService,
    private firebaseMessagingService: FirebaseMessagingService,
    private roleService: RoleService,
    private emitterService: EmittersService,
    private documentService: DocumentService
  ) { }

  ngOnInit(): void {
    this.user = this.userService.getSessionUser();
    this.firmId = this.user.firm._id;
  }

  getProjectStaffList(): void {
    this.projectStaffList = {};
    if (this.projectId)
      this.projectService.getProjectStaffList(this.projectId).subscribe({
        next: (resp) => {
          this.projectPositionsListed = resp.positionsListed;
          this.projectPositionsFilled = resp.positionsFilled;
          this.projectStaffList = resp.allStaff;
          this.projectStaffList.map((role: any) => {
            role.currentlyAssigned = [];
            role.formerlyAssigned = [];
            if (role.positionsList && role.positionsList.length) {
              
              role.positionsList.map((position: any) => {
                if (position.status !== 'QUIT' && position.status !== 'FIRED' && position.status !== 'COMPLETED') {
                  role.currentlyAssigned.push(position);
                }
                else {
                  role.formerlyAssigned.push(position);
                }
              });
            }
          });
        }
      })
  }

  documentKey(reviewer: any): Array<string>{
    return Object.keys(reviewer.docs.blank);
  }

  getMessageNoOfDocumentUploadedByUser(reviewer: any): string{
    return `${Object.keys(reviewer?.docs?.filled[reviewer._id] || {}).length} of ${Object.keys(reviewer?.docs?.blank || {}).length} documents completed`
  }

  isUserCompletedOrPartiallyCompletedTheDocuments(reviewer: any): string{
    const documents = Object.keys(reviewer?.docs?.blank || {});
    const uploadedDocuments = Object.keys(reviewer?.docs?.filled[reviewer._id] || {});

    if(!uploadedDocuments.length){
      return constants.documentUploadStatus.NOT_UPLOADED;
    }

    if(documents.length > uploadedDocuments.length){
      return constants.documentUploadStatus.PARTIALLY_UPLOADED;
    }

    return constants.documentUploadStatus.UPLOADED;
  }

  showFireReviewerDialog($event:Event, reviewerId: string, positionId: string, role: any, firstName: string, lastName: string) {
    $event.stopPropagation();
    this.ref = this.dialogService.open(FireReviewerConfirmation, {
      data: {
        fullName: `${firstName} ${lastName}`
      },
      header: 'Confirmation',
      width: '30vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });

    this.ref.onClose.subscribe((data: FireReviewerPayload) => {
      if (data) {
        this.projectService.fireReviewerFromProject(reviewerId, positionId, data.endMessage, data.isDRSSubmittedChecked).subscribe({
          next: (response) => {
            response.isDRSSubmitted = !!data.isDRSSubmittedChecked;
            this.toastService.showSuccess('Fired successfully');
            this.updateAndReInitializeStaff.emit();
            this.openRatingReviewerModal({
              reviewerId, 
              positionId,
              projectId: this.projectId,
              roleId: role,
              firstName,
              lastName,
              projectDetails: this.projectDetails
            });
          },
          error: (error) => {
            this.toastService.showError('Error while firing reviewer');
          }
        });
      }
    });
  }

  openReviewerDocumentDetail(reviewer: any): void{
    this.dialogService.open(ViewReviewerDocumentsModalComponent, {
      header: `${reviewer.firstName} ${reviewer.lastName} Documents`,
      width: '70vw',
      height: '75vh',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      data: {...reviewer}
    });
  }

  async openRatingReviewerModal(data: any): Promise<void>{
    const firmFavorites = await this.firmService.getFirmFavourites(this.firmId || '');
    const rateReviewerModalRef = this.dialogService.open(RateFreelancersComponent, {
      header: `Rate ${data.firstName} ${data.lastName}`,
      width: '50vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      data: {
        ...data,
        firmFavorites: firmFavorites && firmFavorites.favorites.length ? firmFavorites.favorites : [],
        firmId: this.firmId
      }
    });

    rateReviewerModalRef.onClose.subscribe(async (modalResponse) => {
      if(modalResponse && modalResponse.isConfirm){
        const ratingInfo = {...modalResponse.ratingInfo};
        const roleReviewersRating: any = {
          rate: []
        };

        const rate = {
          position: ratingInfo.positionId,
          stars: ratingInfo.rating,
          role: ratingInfo.roleId,
          isRestricted: ratingInfo.isRestricted,
          workTogether: ratingInfo.workTogether
        }

        roleReviewersRating.rate.push(rate);

        try{
          await this.projectService.submitReviewerRating(this.projectId, roleReviewersRating);
          if(ratingInfo.notes){
            await this.notesService.addNotes(data.reviewerId, ratingInfo.notes);
          }
          this.toastService.showSuccess('Reviewer ratings submitted successfully');
          this.updateAndReInitializeStaff.emit();
        }catch(error){
          this.toastService.showError(`Error occured while submitting the ratings for reviewer ${data.firstName} ${data.lastName}`)
        }

      }
    })
  }

  async openBulkRatingReviewerModal(data: any): Promise<void>{
    const rateReviewerModalRef = this.dialogService.open(RateBulkFreelancersComponent, {
      header: 'Rate Freelancers',
      width: '50vw',
      height: '60vh',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      data: {
        projectId: this.projectId,
        endRole: true,
        reviewers: data,
        codeName: this.projectDetails.codeName,
        firmId: this.firmId
      }
    });

    rateReviewerModalRef.onClose.subscribe(async (modalResponse) => {
      if(modalResponse && modalResponse.isConfirm){
        try{
          await this.projectService.submitReviewerRating(this.projectId || '', modalResponse.roleReviewersRating);
          if(modalResponse.notes && modalResponse.notes.length){
            await this.notesService.bulkAdd(modalResponse.notes);
          }
          this.toastService.showSuccess('Reviewer ratings submitted successfully');
          this.hideDeleteModal(true);
        }catch(error: any){
          this.toastService.showError(error && error.message ? error.message : 'Some error has occurred')
        }
      }
    });
  }

  onRowExpand(event: TableRowExpandEvent) { }

  onRowCollapse(event: TableRowCollapseEvent) { }

  async showMailDialog(projectId: string, emailType: string, firm: any, recipient: string, roleId: string, reviewerId: string) {
    const projectOwners = await this.firmService.getFirmProjectOwners(firm._id);
    this.dialogService.open(SendMailModalComponent, {
      header: 'Send Email',
      width: '50vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      },
      data: {
        projectId,
        emailType,
        firm,
        recipient,
        roleId,
        reviewerId,
        projectOwners: projectOwners.users
      }
    });
  }

  openReviewerDialog(id:string){
    this.reviewerDetailDialogService.openDialog(id);
  }

  openCreateChatReviewer(position:any){

    let node = {
      projectId:position.project,
      roleId:position.role,
      reviewerId:position.reviewer._id,
      msgType: 'REVIEWER',
      reviewer: position.reviewer,
      status: position.status,
      from: 'staff-tab'
    }
    this.messageCompoenentService.openCreateDiscussion(node);
  }

  openCreateChat(role:any){

    let node = {
      projectId: role.positionsList[0].project,
      roleId: role._id,
      reviewerId: null,
      msgType: "ROLE",
      title: role.roleType,
      status: role.status,
      node: role
    };
    this.messageCompoenentService.openNewMessageDialog(node);
  }

  openGroupChat(){

    let node = {
      projectId: this.projectDetails.id,
      roleId: null,
      reviewerId: null,
      msgType: "PROJECT",
      title: this.projectDetails.codeName,
      status: this.projectDetails.status,
      node: this.projectDetails
    };
    this.messageCompoenentService.openNewMessageDialog(node);
  }

  async openImmediateSendOfferModal(role: any, reviewer: any): Promise<void> {
    const selectedRole = await lastValueFrom(this.roleService.getProjectRole(this.projectId, role));
    reviewer = {
      ...reviewer,
      roleId : role,
      projectId: this.projectId,
      isStarted: selectedRole.isStarted? selectedRole.isStarted: selectedRole.status === constants.roleStatus.started? true : false
    }
    this.ref = this.dialogService.open(
      OfferConfirmationModalComponent,
      this.getOfferModalConfig('Do you want to send an offer to this candidate?', reviewer)
    );

    this.ref.onClose.subscribe(async (offer: Offers) => {
      if (offer) {
        const payload = {
          ...offer,
          positionStarted: offer.positionStarted,
          reviewerId: reviewer._id,
          roleId: role,
        };
        await this.offerService.createOffer(payload);
        this.toastService.showSuccess('Offer Sent Successfully');
      }
    })
  }
  

  getOfferModalConfig(modalType: string, data?: any) {
    return {
      header: modalType,
      width: data?.isStarted? '50vw' : '25vw',
      baseZIndex: 10000,
      contentStyle: { overflow: 'visible' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw',
      },
      data: {
        ...data,
      },
    };
  }

  getUnreadMessageCount(){
    this.firebaseMessagingService.getFirmUnReadMessages().subscribe((unReadDiscussions:any)=>{
      this.unReadDiscussions = unReadDiscussions;
    });
  }

  async openDeleteModal(data:any){
    this.roleService.getProjectRole(this.projectDetails._id, data._id).subscribe({
      next: (response) => {
        this.visibleDeleteModal = true;
        this.selectedDeleteRole = response;
        this.isUnreadMessagesExist = !!(this.unReadDiscussions.find(discussion => {
          return discussion.roleId === this.selectedDeleteRole._id && discussion.pmUnReadMessageCount > 0;
        }));
        this.restartDate = moment(new Date()).utc().toDate();
      }
    });
  }
  hideDeleteModal(getRoleApi?:boolean){
    if(getRoleApi){
      this.updateAndReInitializeStaff.emit();
    }
    this.deleteRoleComment = '';
    this.visibleDeleteModal = false;
  }

  deleteRole(){
    if(this.projectDetails.id){
      if(this.selectedDeleteRole && this.selectedDeleteRole.status === constants.roleStatus.active){
        this.projectService.deleteProjectRole(this.projectId, this.selectedDeleteRole._id, this.deleteRoleComment).subscribe(async (resp:any)=>{
          this.toastService.showSuccess('Role Deleted Successfully');
          if(this.selectedDeleteRole.isStarted && this.selectedDeleteRole.occupiedPositions){
            const roleReviewers = await this.roleService.getProjectRoleReviewers(this.projectId || '', this.selectedDeleteRole._id, true);
            if(roleReviewers && roleReviewers.reviewers.length){
              this.openBulkRatingReviewerModal(roleReviewers);
            }
          }
          this.hideDeleteModal(true);
        })
      }
    }
  }

  presumptiveApproveDeny($event: Event, position: any, roleName: string) {
    const data = {
      heading: `confirm or deny ${position.reviewer.firstName} ${position.reviewer.lastName}'s position on role "${roleName}"?`,
      from: 'staffTab',
      textAreaHeading: 'Reason'
    }
    $event.stopPropagation();
      this.ref = this.dialogService.open(PresumptiveApprovalModalComponent, {
          data: data,
          header: 'Confirm Presumptive Position',
          width: '40vw',
          contentStyle: { overflow: 'auto' },
          breakpoints: {
              '960px': '75vw',
              '640px': '90vw'
          }
      });
      this.ref.onClose.subscribe((data: any) => {
          if (data.approve) {
            this.projectService.approveReviewerFromPosition(position.reviewer._id, position._id, data.reason).then(()=>{
              this.toastService.showSuccess("Position successfully approved.");
              this.emitterService.staffTabEmitter.emit();
            })
          }
          if(data.deny){
            this.projectService.denyReviewerFromPosition(position.reviewer._id, position._id, data.reason).then(()=>{
              this.toastService.showSuccess("Position successfully denied.");
              this.emitterService.staffTabEmitter.emit();
            })
          }  
      });
  }

  downloadDocuments(){
    if(this.projectBundle){
      window.open(this.documentService.getDocumentPathById(this.projectBundle._id) || '', '_blank');
    }
  }

  getDateTimeObjWithTime(hours: number, mins: number) {
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(mins);
    return date;
  }
}
