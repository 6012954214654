<app-heading [title]="'Skills and Experience'" [button]="false" [isbreadcrumb]="false"
    [showToggle]="false"></app-heading>

<!-- No Data Skills and Experience -->
<div class="flex flex-column justify-content-center align-items-center h-page-full" *ngIf="false">
    <div class="flex flex-column gap-1 h5 text-center">
        <span>You don't have any education experience entered.</span>
        <span>Please Add experience</span>
    </div>
</div>
<!-- No Data Skills and Experience  End-->

<!-- Create Skills and Experience -->
<div class="flex flex-column justify-content-between h-page-full" *ngIf="true">
    <div class="grid xl:w-9 w-full pt-4">
        @for (scl of skillsCriteriaListing; track scl;let i = $index) {
            <div class="col-12 pb-5">
                <p class="mt-0 mb-2 text-lg font-normal">{{scl.name}} <span class="text-sm">{{scl.description}}</span></p>
                <p-floatLabel>
                    <p-multiSelect [(ngModel)]="scl.criteriaSelectedOption" [options]="scl.label.values" optionLabel="val" inputId="reviewExperience"
                        placeholder="Select all that apply"/>
                    <label for="reviewExperience">Select all that apply</label>
                </p-floatLabel>
                @if(scl.criteriaSelectedOption.length > 0){
                <div class="flex align-items-center gap-2 flex-wrap pt-2">
                    @for (options of scl.criteriaSelectedOption; track options) {
                        <p-chip [label]="options.val" (onRemove)="onRemoveChip(options,i)" [removable]="true" />
                        @if(options.isExperienceEnabled){
                            <p-dropdown [options]="scl.experienceLevels" optionLabel="experience" [(ngModel)]="options.selectedExperienceLevel"
                            placeholder="Select experience level"/>
                        }
                    }
                </div>
                }
            </div>
        }
    </div>
    <div class="flex gap-3 mt-3">
        <p-button label="Back" rounded="true" outlined="true" size="large"
            styleClass="white-space-nowrap" (onClick)="onBack()"></p-button>
        <p-button (onClick)="save()"  label="Save" rounded="true" size="large" styleClass="white-space-nowrap freshgreen-background"></p-button>
    </div>
</div>
<!-- Create Skills and Experience End-->