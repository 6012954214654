<div class="grid grid-nogutter mb-3">
  <div class="col-12 md:col-6 sm:col-12 sm:mb-2">
      <h1 class="h4 m-0">Invite Candidates</h1>
  </div>
  <div class="col-12 md:col-6 sm:col-12 flex md:justify-content-end justify-content-between mt-3 md:mt-0 align-items-start sm:align-items-center  md:gap-4 gap-0 ">
    <p-button (click)="showBulkInviteDialog()" styleClass="vibrant-pink-background" [label]="'Bulk Invite'" [rounded]="true" size="large"/>
    <p-button (click)="showShareableInviteDialog($event)" [label]="'Shareable Invite link'" [rounded]="true" size="large"/>
  </div>
</div>
<form [formGroup]="inviteForm">
  <div class="grid grid-nogutter">

    <div class="col-12 lg:col-4 xl:lg:col-4 mb-3">
      <p-floatLabel>
        <input formControlName="firstName" type="text" pInputText />
        <label for="firstName">First Name<span class="required">*</span></label>
      </p-floatLabel>
      @if((getFormValue('firstName')?.dirty || getFormValue('firstName')?.touched) && getFormValue('firstName')?.errors?.['required']){
        <div class="p-error ">This is required.</div>
      }
    </div>

    <div class="col-8"></div>

    <div class="col-12 lg:col-4 xl:lg:col-4 mb-3">
      <p-floatLabel>
        <input formControlName="lastName" type="text" pInputText />
        <label for="lastName">Last Name<span class="required">*</span></label>
      </p-floatLabel>
      @if((getFormValue('lastName')?.dirty || getFormValue('lastName')?.touched) && getFormValue('lastName')?.errors?.['required']){
        <div class="p-error ">This is required.</div>
      }
    </div>

    <div class="col-8"></div>

    <div class="col-12 lg:col-4 xl:lg:col-4 mb-3">
      <p-floatLabel>
        <input formControlName="email" type="email" pInputText />
        <label for="email">Email<span class="required">*</span></label>
      </p-floatLabel>
      @if((getFormValue('email')?.dirty || getFormValue('email')?.touched) && getFormValue('email')?.errors?.['required']){
        <div class="p-error ">This is required.</div>
      }
      @if(getFormValue('email')?.errors?.['pattern']){
        <div class="p-error ">Invalid Email.</div>
      }
    </div>

    <div class="col-8"></div>

    <div class="col-12 lg:col-4 xl:lg:col-4 mb-3">
      <p-floatLabel>
        <input (input)="formatContact($event)" maxlength="12" placeholder="000-000-0000" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" formControlName="phone" type="text" pInputText />
        <label for="phone">Phone</label>
      </p-floatLabel>
    </div>

    <div class="col-8"></div>

    @if(jobTypes && jobTypes.length){
      <div class="col-12 lg:col-4 xl:lg:col-4 mb-3">
        <p-multiSelect 
          [options]="jobTypes" 
          formControlName="jobTypes" 
          optionLabel="name"
          placeholder="Select job type" />
      </div>

      <div class="col-8"></div>
    }

    @if(isPwcEnabled){
      <div class="col-12 lg:col-4 xl:lg:col-4 mb-3">
        <p-floatLabel>
          <input formControlName="pwcEmail" type="email" pInputText />
          <label for="pwcEmail">PWC Email<span class="required">*</span></label>
        </p-floatLabel>
        @if((getFormValue('pwcEmail')?.dirty || getFormValue('pwcEmail')?.touched) && getFormValue('pwcEmail')?.errors?.['required']){
          <div class="p-error ">This is required.</div>
        }
        @if(getFormValue('pwcEmail')?.errors?.['pattern']){
          <div class="p-error ">Invalid Email.</div>
        }
      </div>

      <div class="col-8"></div>

      <div class="col-12 lg:col-4 xl:lg:col-4 mb-3">
        <p-floatLabel>
          <input formControlName="primaryPayRate" type="number" pInputText min="1" max="100" />
          <label for="primaryPayRate">Primary Pay Rate<span class="required">*</span></label>
        </p-floatLabel>
        @if((getFormValue('primaryPayRate')?.dirty || getFormValue('primaryPayRate')?.touched) && getFormValue('primaryPayRate')?.errors?.['required']){
          <div class="p-error ">This is required.</div>
        }
      </div>

      <div class="col-8"></div>

      <div class="col-12 lg:col-4 xl:lg:col-4 mb-3">
        <p-floatLabel>
          <input formControlName="secondaryPayRate" type="number" pInputText min="1" max="100" />
          <label for="secondaryPayRate">Secondary Pay Rate</label>
        </p-floatLabel>
      </div>

      <div class="col-8"></div>

      <div class="col-12 lg:col-4 xl:lg:col-4 mb-3">
        <p-floatLabel>
          <p-dropdown
              formControlName="ranking"
              [options]="rankings"
              optionLabel="value"
              inputId="float-label"
              />
          <label for="ranking">Ranking<span class="required">*</span></label>
      </p-floatLabel>
      </div>

      <div class="col-8"></div>

      <div class="col-12 lg:col-4 xl:lg:col-4 mb-3">
        <p-floatLabel>
          <label for="rating">Rating</label>
          <p-rating formControlName="rating" [cancel]="false" class="cursor-default" />
        </p-floatLabel>
      </div>  

      <div class="col-8"></div>
    }


    <div>
      <p-button
        class="col-12 lg:col-4 xl:lg:col-4"
        (click)="back()"
        [rounded]="true"
      >
        Back
      </p-button>
      <p-button
        class="col-12 lg:col-4 xl:lg:col-4"
        (click)="onSubmit()"
        [rounded]="true"
        [disabled]="!inviteForm.valid"
      >
        Submit
      </p-button>
    </div>
  </div>
</form>
