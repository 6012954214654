import { Component, OnInit } from '@angular/core';
import {
    DialogService,
    DynamicDialogModule,
    DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { UserService } from '../../../../../../../../shared/services/user.service';
import { InputNumberModule } from 'primeng/inputnumber';
import { constants } from '../../../../../../../../shared/constants/constants';
import { MultiSelectModule } from 'primeng/multiselect';
import { ToastService } from '../../../../../../../../shared/services/toast.service';

@Component({
    selector: 'ranking-selector-modal',
    providers: [DialogService],
    standalone: true,
    imports: [
        FloatLabelModule,
        DialogModule,
        ButtonModule,
        FormsModule,
        CommonModule,
        DynamicDialogModule,
        InputNumberModule,
        MultiSelectModule
    ],
    templateUrl: './ranking-selector-modal.component.html',
    styleUrl: './ranking-selector-modal.component.scss'
})
export class RankingSelectorModalComponent implements OnInit {

    sessionUser: any = null;
    value: any;
    rankings: any = [];

    constructor(
        public ref: DynamicDialogRef,
        private toastService: ToastService,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.sessionUser = this.userService.getSessionUser();
        this.getRankings();
    }

    getRankings(){
        Object.values(constants.userRankings).forEach((val) => {
          this.rankings.push({id: val.toUpperCase(), name: val})
        });
    }

    closeDialog() {
        if(!this.value){
            this.toastService.showError('Ranking can\'t be empty');
        }
        else{
            this.value = this.value.map((res: any) => {return res.id});
            this.ref.close({type: this.value});
        }
    }
}
