import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AllComponent } from './all/all.component';
import { WorkedTogetherComponent } from './worked-together/worked-together.component';
import { RestrictedComponent } from './restricted/restricted.component';
import { TalentPoolModule } from './talent-pool/talent-pool.module';
import { InviteCandidateComponent } from './invite-candidate/invite-candidate.component';
import { PendingCandidatesComponent } from './pending-candidates/pending-candidates.component';
const routes: Routes = [
  { path: '', redirectTo: 'all', pathMatch: 'full' },

      {
        path: 'all',
        component: AllComponent
      },
      {
        path: 'workTogether',
        component: WorkedTogetherComponent
      },
      {
        path: 'restricted',
        component: RestrictedComponent
      },
      {
        path: 'talentPool',
        loadChildren: () => TalentPoolModule
      },
      {
        path: 'invite-candidate',
        component: InviteCandidateComponent
      },
      {
        path: 'pending-candidates',
        component: PendingCandidatesComponent
      },
      {
        path: 'expired-credential-candidates',
        component: PendingCandidatesComponent
      },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PeopleRoutingModule { }
