<div>
    
    <!-- <div class="pt-10"> -->
    <div class="w-full flex flex-column gap-3 pb-3">
      <div class="flex gap-1">
        Select Rate Type:
      </div>
    </div>
    <div class="count flex align-items-center gap-1 md:gap-2 lg:gap-2 justify-content-between md:justify-content-start pb-3">
      <div class="flex align-items-center">
        <p-radioButton [value]="'primary'" [(ngModel)]="payRateType" inputId="primary" />
        <label for="primary" class="ml-2">
            Primary
        </label>
      </div>
      <div class="flex align-items-center">
          <p-radioButton [value]="'secondary'" [(ngModel)]="payRateType" inputId="secondary" />
          <label for="secondary" class="ml-2">
              Speciality
          </label>
      </div>
    </div>

    <div class="w-full flex flex-column gap-3 pt-10">
      <div class="flex gap-1">
        Select Min & Max Range:
      </div>
    </div>

    <div class="pt-10 gap-1 md:gap-2 lg:gap-2 space">
      <p-inputNumber [(ngModel)]="minValue" [style]="{'width': '4rem'}"
        styleClass="slider-style" [showButtons]="true" buttonLayout="vertical"
        spinnerMode="vertical" inputId="vertical" [min]="1" [max]="maxValue"
        decrementButtonClass="p-button-text" incrementButtonClass="p-button-text"
        incrementButtonIcon="pi pi-chevron-up" decrementButtonIcon="pi pi-chevron-down"/>

      <p-inputNumber class="pl-10" [(ngModel)]="maxValue" [style]="{'width': '4rem'}"
        styleClass="slider-style" [showButtons]="true" buttonLayout="vertical"
        spinnerMode="vertical" inputId="vertical" [min]="minValue" max="100"
        decrementButtonClass="p-button-text" incrementButtonClass="p-button-text"
        incrementButtonIcon="pi pi-chevron-up" decrementButtonIcon="pi pi-chevron-down"/>
    </div>
    
    <div class="flex justify-content-end pt-10">
      <p-button
        label="Done"
        size="large"
        [rounded]="true"
        [outlined]="true"
        (onClick)="closeDialog()"
        [ngStyle]="{'margin-right': '10px'}"      />
    </div>
</div>
  