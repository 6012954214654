import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { ChipModule } from 'primeng/chip';
import { IconFieldModule } from 'primeng/iconfield';
import { InputTextModule } from 'primeng/inputtext';
import { InputIconModule } from 'primeng/inputicon';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { HeadingComponent } from '../../../../../shared/components/heading/heading.component';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SearchMarkets, SearchMarket, SearchReviewerQueryParams, SearchReviewerResponse, Reviewer, UpdateUserStatus } from '../people.interface';
import { PeopleService } from '../../../../../shared/services/people.service';
import { ConfigurationService } from '../../../../../shared/services/configuration.service';
import { constants } from '../../../../../shared/constants/constants';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ConfirmationDialogService } from '../../../../../shared/services/confirmation-dialog.service';
import { ToastService } from '../../../../../shared/services/toast.service';
import { UserService } from '../../../../../shared/services/user.service';
import { DeleteUserByFirm, UserClientVm } from '../../../../../store/user/user.interface';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RatingModule } from 'primeng/rating';
import {CredentialingComponent} from './modals/credentialing/credentialing.component'
import { ReviewerDetailDialogService } from '../../../../../shared/services/reviewer-detail-dialog.service';
import { DeleteUserConfirmation } from '../all/modals/delete/delete-user-confirmation.component';
import { TooltipModule } from 'primeng/tooltip';
@Component({
  selector: 'app-pending-candidates',
  standalone: true,
  imports: [DropdownModule, ButtonModule, FormsModule,RatingModule, ChipModule, IconFieldModule, InputIconModule, InputSwitchModule, HeadingComponent, InputTextModule, TableModule, CommonModule, TooltipModule],
  templateUrl: './pending-candidates.component.html',
  styleUrl: './pending-candidates.component.scss',
})
export class PendingCandidatesComponent implements OnInit {
  reviewerCount: number = 0;
  searchMarkets: any = [];
  expiredCredentialsCandidateView: boolean = false;  
  reviewers: Reviewer[] = [];
  pageTitle: string;
  filterStaffedCandidates: boolean = true;
  userObject!: UserClientVm;
  ref: DynamicDialogRef | undefined;
  infoRef: DynamicDialogRef | undefined;
  isPwcEnabled = false;

  constructor(private route: ActivatedRoute, private peopleService: PeopleService, private configurationService: ConfigurationService,
    private confirmationDialogService: ConfirmationDialogService, private toast: ToastService, private userService: UserService,
    public dialogService: DialogService,private reviewerDetailDialogService:ReviewerDetailDialogService, private router:Router
  ) {
    this.isPwcEnabled = this.configurationService.getPwcFlag();
    this.expiredCredentialsCandidateView = this.router.url.includes('expired-credential-candidates');
    this.pageTitle = this.expiredCredentialsCandidateView? "Expired Credentials" : "Pending Candidates";
    this.userObject = this.userService.getSessionUser();
  }

  ngOnInit() {
    
  }
  gotoCredentials(){
    this.router.navigateByUrl('/client/people/expired-credential-candidates')
  }

  onExpiredStaffedFilterToggleSwitch(){
    this.getReviewers({offset:0,limit:10});
  }

  getSearchMarket() {
    const searchMarkets: SearchMarkets = this.route.snapshot.data['searchMarket'];
    searchMarkets.map((d: SearchMarket) => {
      this.searchMarkets.push(d._id);
    })
  }
  
  
  getReviewers(event?: any) {
    const offset = event.first;
    const limit = event.rows;
    const queryParams: SearchReviewerQueryParams = {
      sortOrder: constants.sortingOrder.descending,
      offset: offset,
      limit: limit,
      searchOn: 'all',
    };
    let subscribedList;
    if(this.expiredCredentialsCandidateView){
      queryParams.filterStaffedCandidates = this.filterStaffedCandidates;
      subscribedList = this.peopleService.getExpiredCredentialCandidateList(queryParams, this.userObject.firm._id)
    } else{
      subscribedList = this.peopleService.getPendingCandidateList(queryParams, this.userObject.firm._id)
    }
    subscribedList.subscribe((response: SearchReviewerResponse) => {
      this.reviewers = response.users.map((user: Reviewer) => {
        const currentTenant = this.userService.getUserCurrentTenant(user);
        return {
          ...user,
          defaultRating: currentTenant && currentTenant.defaultRatings ? currentTenant.defaultRatings : 0
        }
      });
      this.reviewerCount = response.count;
    })
  }
  
  approveReviewer($event: Event, reviewer: Reviewer) {
    const mesg = `You are about to approve ${reviewer.firstName} ${reviewer.lastName}. Are you sure?`
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: mesg,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.userService.approveUser(reviewer._id).subscribe((response: any) => {
          this.getReviewers({ first: 0, rows: 10 });
          this.toast.showSuccess('Candidate Approved Successfully')
        })
      }
    });
  }
  isReviewerApproveDisable(reviewer: Reviewer) {
    const tenant = reviewer.tenants.find(tenant => tenant.firm === this.userObject.firm._id);
    return (tenant && tenant.reviewerStatus !== constants.userVerificationStatus.pendingAdminVerification);

  }
  showInfoDialog($event: Event,reviewer: Reviewer) {
    const data = {
      reviewer: reviewer,
      expiredCredentialsCandidateView:this.expiredCredentialsCandidateView
    }
    this.infoRef = this.dialogService.open(CredentialingComponent, {
      data: data,
      header: 'Credentialing and Education',
      width: 'calc(100vw - 100px)',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });
    this.infoRef.onClose.subscribe((resp: any) => {
      this.getReviewers({ first: 0, rows: 10 });
      if (resp && resp.from) {
        const from = resp.from
        if(from === 'info'){
          this.approveReviewer($event, reviewer);
        }else if( from === 'backgroundCheck' ||  from === 'nsc'){
          this.invokeBackground($event, reviewer,resp.data, from);
        }
      }
  });
  }
  invokeBackground($event: Event, reviewer: Reviewer, data:any, from:string) {
    const mesg = `You are about to send following background check request for ${data.type}. Are you sure? `
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: mesg,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        const requestObject = { type: data.type, typeId: data._id || undefined };
        if(from === 'nsc'){
          this.userService.invokeEducationBackgroundCheck(reviewer._id,requestObject).subscribe((response: any) => {
            this.toast.showSuccess('Background Check invoked successfully')
          })
        }else{
          this.userService.invokeBackgroundCheck(reviewer._id,requestObject).subscribe((response: any) => {
            this.toast.showSuccess('Background Check invoked successfully')
          })
        }
       
      }
    });
  }
  openReviewerDialog(id:string){
    this.reviewerDetailDialogService.openDialog(id);
  }

  showDeleteReviewerConfirmation($event:Event,reviewer:Reviewer) {
    const data = {
      heading: `Are you sure you want to archive ${reviewer.firstName} ${reviewer.lastName}`,
      from: 'deleteReviewer',
      textAreaHeading: 'Comment'
    }
    $event.stopPropagation();
      this.ref = this.dialogService.open(DeleteUserConfirmation, {
          data: data,
          header: 'Confirmation',
          width: '30vw',
          contentStyle: { overflow: 'auto' },
          breakpoints: {
              '960px': '75vw',
              '640px': '90vw'
          }
      });

      this.ref.onClose.subscribe((data: DeleteUserByFirm) => {
          
          if (data) {
            const payload: DeleteUserByFirm = {
              reason : data.reason,
              comments: data.comments,
              firm : this.userObject.firm._id
            }
            this.userService.deleteUserByFirm(reviewer._id, payload).subscribe((response: any) => {
              this.getReviewers({ first: 0, rows: 10 });
              this.toast.showSuccess('User archived successfully')
            })
          }
          
      });
  }
}