import { Component } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { ConfigurationService } from '../../../../../../../shared/services/configuration.service';

@Component({
  selector: 'app-bulk-invite-response',
  standalone: true,
  imports: [
    ButtonModule,
    TableModule,
    TooltipModule,
    InputSwitchModule,
    FormsModule
  ],
  templateUrl: './bulk-invite-response.component.html',
  styleUrl: './bulk-invite-response.component.scss'
})
export class BulkInviteReponseComponent {

  bulkInviteResponseInfo = {
    users: []
  }

  failedInvitedCandidates: Array<any> = [];
  successfulInvitedCandidates: Array<any> = [];
  showSuccessfullInviteSent = true;
  isPwcEnabled = false;

  constructor(
    private bulkInviteResponseRef: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private configurationService: ConfigurationService
  ) { }

  ngOnInit(): void {
    this.isPwcEnabled = this.configurationService.getPwcFlag();
    this.initialization();
  }
  
  initialization(): void {
    this.bulkInviteResponseInfo = {...this.config.data};
    this.getSuccessInvitedCandidates();
    this.getFailedInvitedCandidates();
    if(this.getFailedInvitedCandidates().length){
      this.showSuccessfullInviteSent = false;
    }
  }

  onConfirm(): void{
    this.bulkInviteResponseRef.close();
  }

  getSuccessInvitedCandidates(): Array<any>{
    this.successfulInvitedCandidates = this.bulkInviteResponseInfo.users.filter((user: any) => user.inviteSent);
    return this.successfulInvitedCandidates
  }

  getFailedInvitedCandidates(): Array<any>{
    this.failedInvitedCandidates = this.bulkInviteResponseInfo.users.filter((user: any) => !user.inviteSent);
    return this.failedInvitedCandidates;
  }

  isJobTypeExist(): boolean{
    if(this.bulkInviteResponseInfo && this.bulkInviteResponseInfo.users.length){
      return !!this.bulkInviteResponseInfo.users.find((user: any) => (user.jobTypes && user.jobTypes.length))
    }

    return false;
  }
}
