<div>
  <form [formGroup]="createForm">
    <div class="grid grid-nogutter">
      <div class="col-12 p-2">
        <p-floatLabel>
          <input formControlName="name" type="text" pInputText />
          <label for="name">Name<span class="required">*</span></label>
        </p-floatLabel>
      </div>
      <div class="col-12 p-2">
        <p-dropdown [options]="statusOptions" formControlName="isActive" placeholder="Select Status" appendTo="body">
        </p-dropdown>
      </div>
    </div>
    <div class="flex justify-content-end gap-2">
      <p-button label="CANCEL" size="large" [rounded]="true" [outlined]="true" (onClick)="closeDialog()" />
      <p-button styleClass="freshgreen-background" label="{{buttonText}}" size="large" [disabled]="!createForm.valid"
        [rounded]="true" (onClick)="onSubmit()" />
    </div>
  </form>
</div>