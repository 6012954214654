<div class="align-items-center rating-bulk-container">
    <div class="flex">
        <i pTooltip="Successfully invite sent" class="pi pi-check-circle freshgreen mr-2" style="font-size: 1rem;"></i>
        <div class="flex  flex-column align-items-start justify-content-center gap-2">
            Invite sent to {{successfulInvitedCandidates.length}} candidate(s) successfully.
        </div>
    </div>
    <div class="flex my-2">
        <i pTooltip="Failed to sent invite" class="pi pi-times-circle p-error mr-2" style="font-size: 1rem;"></i>
        <div class="flex  flex-column align-items-start justify-content-center gap-2">
            Failed to sent invite to {{failedInvitedCandidates.length}} candidate(s) mentioned in the list below.
        </div>
    </div>

    <div class="flex gap-2">
        <div class="flex flex-column align-items-start justify-content-center">
            Toggle to see successful/failed invite sent.
        </div>
        <p-inputSwitch class="ml-3" [(ngModel)]="showSuccessfullInviteSent" class="mpl-switch" inputId="stfProject" ariaLabel="My Projects" />
    </div>
    
    <div class="col-12  flex flex-column border-1 surface-border overflow-auto">

        <p-table 
            [value]="showSuccessfullInviteSent ? getSuccessInvitedCandidates() : getFailedInvitedCandidates()"             
            styleClass="p-datatable-sm p-datatable-sm--normal" 
            [paginator]="true" [rows]="10" 
            [tableStyle]="{ 'min-width': '50rem' }"
            [showCurrentPageReport]="true" 
            currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
            autoLayout="true">
            <ng-template pTemplate="header">
            <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Invite Status</th>
                @if(isJobTypeExist()){
                    <th>Job Type</th>
                }
                @if(isPwcEnabled){
                    <th>
                        PWC Email
                    </th>
                    <th>
                        Primary Pay Rate
                    </th>
                    <th>
                        Secondary Pay Rate
                    </th>
                    <th>
                        Rating
                    </th>
                }
            </tr>
            </ng-template>
            <ng-template pTemplate="body" let-item>
            <tr>
                <td>{{ item.firstName }} {{item.lastName}}</td>
                <td>{{ item.email }}</td>
                <td>
                    @if(showSuccessfullInviteSent){
                        <i pTooltip="Successfully invite sent" class="pi pi-check-circle freshgreen mr-2" style="font-size: 1rem;"></i>
                    } @else {
                        <i pTooltip="Failed to sent invite" class="pi pi-times-circle p-error" style="font-size: 1rem;"></i>
                    }
                </td>
                @if(isJobTypeExist()){
                    <td>
                        @if(item.jobTypes && item.jobTypes.length){
                            @for(jobType of item.jobTypes; track $index){
                                {{jobType}}@if($index !== (item.jobTypes.length - 1)){,} 
                            }
                        }
                    </td>
                }
                @if(isPwcEnabled){
                    <td>{{ item.pwcEmail }}</td>
                    <td>{{ item.primaryPayRate }}</td>
                    <td>{{ item.secondaryPayRate }}</td>
                    <td>{{ item.rating }}</td>
                }
            </tr>
            </ng-template>
        </p-table>
    </div>

    <div class="flex justify-content-end gap-2 mb-3 mt-3">
        <p-button label="Ok" [size]="'large'" (click)="onConfirm()" />
    </div>
</div>