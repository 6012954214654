<div header="Archive Project" class="align-items-center">
    
    <div class="grid grid-nogutter">

        <div class="col-12 lg:col-12 xl:lg:col-12 mb-3">
            <p>
                You can utilize the {{title}} bulk invite CSV template to quickly invite new users you’d like to add to your candidate pool. Download the CSV below and add necessary fields for your new candidates.
            </p>
        </div>

        <div class="col-12 lg:col-12 xl:lg:col-12 mb-3 flex">
            <div class="col-6 lg:col-6 xl:lg:col-6">
                <p-button
                    class="col-12 lg:col-12 xl:lg:col-12"
                    (click)="downloadCSVTemplate()"
                    [rounded]="true"
                    >
                    Download CSV Template
                </p-button>
            </div>
            <div class="col-6 lg:col-6 xl:lg:col-6">
                <p-button
                    class="col-12 lg:col-12 xl:lg:col-12"
                    (click)="uploadBulkInviteCSV.click()"
                    [rounded]="true"
                    >
                    Select CSV File (CSV Files Only)
                </p-button>
                <input 
                    #uploadBulkInviteCSV
                    id="uploadBulkInviteCSV"
                    type="file"
                    class="hidden"
                    value="..."
                    name="file"
                    accept="text/csv"
                    button-disable
                    (change)="uploadCSV($event)">
            </div>
        </div>
    </div>
    @if(file){
        <div>
            <div class="col-md-12">
                <p>
                    @if(csvOrignalData.length && duplicateCsvUsers.length === 0){
                        <div>
                            You’re bulk invite CSV file contains {{csvOrignalData.length}} new candidates.
                        </div>
                    }
                  
                    @if(duplicateCsvUsers.length){
                        <div>
                            There are {{duplicateCsvUsers.length}} user that already exist in your firm and will not be included.
                        </div>
                    }

                    @if(csvOrignalData.length) {
                        <div>
                            Candidates to be invited {{csvOrignalData.length - duplicateCsvUsers.length}}
                        </div>
                    }
                  
                </p>
              </div>
        </div>
        <div class="col-12 p-0">
            <p-table styleClass="p-datatable-sm p-datatable-sm--normal" [value]="csvOrignalData" [paginator]="true" [rows]="5" [tableStyle]="{ 'min-width': '50rem' }"
                [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
                autoLayout="true">
                <ng-template pTemplate="header">
                    <tr>
                        <th>
                            S.No
                        </th>
                        <th>
                            Name
                        </th>
                        <th>
                            Email
                        </th>
                        <th>
                            Phone
                        </th>
                        @if(jobTypeHeaders.length){
                            <th>
                                Job Type
                            </th>
                        }
                        @if(isPwcEnabled){
                            <th>
                                PWC Email
                            </th>
                            <th>
                                Primary Pay Rate
                            </th>
                            <th>
                                Secondary Pay Rate
                            </th>
                            <th>
                                Rating
                            </th>
                        }
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-user let-rowIndex="rowIndex">
                    <tr>
                        <td>{{rowIndex + 1}}</td>
                        <td>{{user.firstName}} {{user.lastName}}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.phone }}</td>
                        @if(jobTypeHeaders.length){
                            <td>
                                @if(user.jobTypes.length){
                                    @for(jobType of user.jobTypes; track $index){
                                        {{jobType}}@if($index !== (user.jobTypes.length - 1)){,} 
                                    }
                                }
                            </td>
                        }
                        @if(isPwcEnabled){
                            <td>{{ user.pwcEmail }}</td>
                            <td>{{ user.primaryPayRate }}</td>
                            <td>{{ user.secondaryPayRate }}</td>
                            <td>{{ user.rating }}</td>
                        }
                    </tr>
                </ng-template>
            </p-table>
        </div>
    }

    <div class="grid grid-nogutter">

    
        <div class="col-12 lg:col-12 xl:lg:col-12 mb-3 align-items-center">
            <p-button
                class="col-12 lg:col-12 xl:lg:col-12"
                [rounded]="true"
                (click)="cancel()"
                >
                Cancel
            </p-button>
            @if(file && csvOrignalData.length){
                <p-button
                    class="col-12 lg:col-12 xl:lg:col-12"
                    (click)="bulkInviteCandidateSubmit()"
                    [rounded]="true"
                    >
                    Yes
                </p-button>
            }
        </div>
    </div>
    
  </div>