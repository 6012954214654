import { Component, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserService } from '../../../../../../../shared/services/user.service';
import { DocumentService } from '../../../../../../../shared/services/document-service';
import { Details, UserClientVm, UserDetails } from '../../../../../../../store/user/user.interface';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { Reviewer } from '../../../people.interface';
import { ConfirmationDialogService } from '../../../../../../../shared/services/confirmation-dialog.service';
import { ToastService } from '../../../../../../../shared/services/toast.service';
import { RequestInformationComponent } from '../request-information/request-information.component';
import { TooltipModule } from 'primeng/tooltip';
import { ManualApprovalComponent } from '../manual-approval/manual-approval.component';
import { constants } from '../../../../../../../shared/constants/constants';
@Component({
  selector: 'app-credentialing',
  standalone: true,
  imports: [TableModule, ButtonModule, CommonModule, TooltipModule],
  templateUrl: './credentialing.component.html',
  styleUrl: './credentialing.component.scss'
})
export class CredentialingComponent implements OnInit {
  credentials: UserDetails = [];
  userObject!: UserClientVm;
  approvalRef: DynamicDialogRef | undefined;
  reqInfo: DynamicDialogRef | undefined;  
  expiredCredentialsCandidateView: boolean;
  constants = constants;

  constructor(private ref: DynamicDialogRef, public config: DynamicDialogConfig, private userService: UserService,
    private documentService: DocumentService, private confirmationDialogService: ConfirmationDialogService,
    private toast: ToastService, public dialogService: DialogService
  ) {
    this.expiredCredentialsCandidateView = this.config.data.expiredCredentialsCandidateView;
   }

  ngOnInit(): void {
    this.userObject = this.userService.getSessionUser();
    this.getAbDetails();
  }


  ranCriminalCheck(){
    let cred = this.credentials.find((c)=>{return c.type === "CRIMINAL";})
    return (cred && cred.bgStatus);
  }
  getAbDetails() {

    this.config.data.reviewer.tenants = this.config.data.reviewer.tenants.filter((d: any) => {
      return d.firm._id === this.userObject.firm._id;
    })
    this.userService.getAbdetails(this.config.data.reviewer._id).subscribe((resp: UserDetails) => {
      if(this.expiredCredentialsCandidateView){
        resp = resp.filter((c)=>{
            return c.type == constants.bgCheckTypes.CREDENTIALS; 
        })
      }
      resp.map((d: Details) => {
        if (d.auditDocumentId) {
          d.downloadURL = this.documentService.getDocumentPathById(d.auditDocumentId);
        }
        if (d.certificate) {
          d.certificateDownloadURL = this.documentService.getDocumentPathById(d.certificate);
        }
      })

      this.credentials = resp;
    })
  }

  closeDialog() {
    this.ref.close();
  }

  backgroundCheck(type: string, data: Details) {
    this.ref.close({ from: type, data: data });
  }

  downloadDocument(url: any) {
    window.open(url, '_self');
  }


  openBackgroundcheckModal($event: Event, summary: any): void{
    const mesg = `You are about to send background check request for following reviewer <br> <strong>${this.config.data.reviewer.firstName} ${this.config.data.reviewer.lastName}</strong>. Are you sure?`
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: mesg,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        const requestObject = { type: 'CRIMINAL', typeId: summary._id || undefined, tenantId: this.userObject.firm._id };
        this.userService.invokeCriminalCheck(this.config.data.reviewer._id, requestObject ).subscribe((response: any) => {
          this.toast.showSuccess('Background Check invoked successfully')
        })
      }
    });
  }

  approveReviewer($event: Event, reviewer: Reviewer) {
    const mesg = `You are about to approve ${reviewer.firstName} ${reviewer.lastName}. Are you sure?`
    this.confirmationDialogService.confirm({
      target: $event.target as EventTarget,
      message: mesg,
      header: 'Confirmation',
      icon: 'pi pi-exclamation-triangle',
      acceptIcon: "none",
      rejectIcon: "none",
      rejectButtonStyleClass: "p-button-text",
      accept: () => {
        this.userService.approveUser(reviewer._id).subscribe((response: any) => {
          this.toast.showSuccess('Reviewer Approve Successfully')
        })
      }
    });
  }

  manualApproveCredential($event: Event, credential: Details) {
    
      const data = {
        reviewer:  this.config.data.reviewer,
        credential: credential

      }
      this.approvalRef = this.dialogService.open(ManualApprovalComponent, {
        data: data,
        header: 'Manual Approval',
        width: 'calc(100vw - 100px)',
        contentStyle: { overflow: 'auto' },
        breakpoints: {
          '960px': '75vw',
          '640px': '90vw'
        }
      });
      this.approvalRef.onClose.subscribe((resp: any) => {
        this.ngOnInit();
      
      });
  }
  

  requestInformation($event: Event): void {
    const data = {
      reviewer: this.config.data.reviewer
    }
    this.reqInfo = this.dialogService.open(RequestInformationComponent, {
      data: data,
      header: `Request additional information from ${data.reviewer.firstName}`,
      width: '35vw',
      contentStyle: { overflow: 'auto' },
      breakpoints: {
        '960px': '75vw',
        '640px': '90vw'
      }
    });
    this.reqInfo.onClose.subscribe((resp: any) => {
    });
  }
}
