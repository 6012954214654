<div class="grid grid-nogutter">
    <div class="col-12 xl:col-4 lg:col-4 mb-3">
        <p-floatLabel>
            <p-dropdown [options]="roles" optionLabel="roleType" [(ngModel)]="selectedRole" inputId="float-label"
                (onChange)="onRoleChange()" />
            <label for="float-label">Select Role</label>
        </p-floatLabel>
    </div>
    <div class="col-12">
        <p-tabView styleClass="full-w" [(activeIndex)]="activeTabIndex" (onChange)="onReviewerTabChange($event)">
            <p-tabPanel header="SELECT CANDIDATES">
                @if(selectedRole){
                <div class="col-12 xl:col-4 lg:col-4">
                    <div
                        class="flex md:align-items-center gap-4 xl:gap-6 justify-content-between flex-column md:flex-row">
                        <div
                            class="count flex align-items-center gap-1 md:gap-2 lg:gap-2 justify-content-between md:justify-content-start rates">
                            <span class="font-bold text-sm mr-auto">
                                Team Size
                            </span>
                            <p-inputNumber [(ngModel)]="selectedRole.positions" [style]="{'width': '4rem'}"
                                styleClass="slider-style" [showButtons]="true" buttonLayout="vertical"
                                spinnerMode="vertical" inputId="vertical" [min]="selectedRole.occupiedPositions"
                                decrementButtonClass="p-button-text" incrementButtonClass="p-button-text"
                                incrementButtonIcon="pi pi-chevron-up" decrementButtonIcon="pi pi-chevron-down"
                                (onInput)="onChangePosition()" />
                            @if(showPositionSaveButton){
                            <p-button (click)="updatePositions(selectedRole.positions || 0)" [rounded]="true"
                                icon="pi pi-save" size="large" />
                            }
                        </div>
                        <div
                            class="count flex align-items-center gap-1 md:gap-2 lg:gap-2 justify-content-between md:justify-content-start">
                            <span class="white-space-nowrap font-bold text-sm mr-auto">
                                Rate {{firm.isPartialRateEnabled ? '' : '$'}}
                            </span>
                            <p-inputNumber [(ngModel)]="selectedRole.hourlyRate" [style]="{'width': '5rem'}"
                                styleClass="slider-style" [showButtons]="true" buttonLayout="vertical"
                                spinnerMode="vertical" inputId="vertical" min="0" decrementButtonClass="p-button-text"
                                incrementButtonClass="p-button-text" incrementButtonIcon="pi pi-chevron-up"
                                decrementButtonIcon="pi pi-chevron-down" [min]="selectedRole.hourlyRateMin"
                                (onInput)="onChangeHour()" [mode]="firm.isPartialRateEnabled ? 'currency': 'decimal'" currency="USD" locale="en-US"/>
                            <span class="font-bold text-sm">
                                {{selectedRole.payType === 'salary' ? '/yr' : '/hr'}}
                            </span>
                            @if(showHourSaveButton){
                            <p-button
                                (click)="updateHourlyRate(selectedRole.hourlyRate, selectedRole.overTime, selectedRole.payType)"
                                [rounded]="true" icon="pi pi-save" size="large" />
                            }
                        </div>
                        @if(isFullTimePermanantProject){
                        <div
                            class="count flex align-items-center gap-1 md:gap-2 lg:gap-2 justify-content-between md:justify-content-start">
                            <div class="p-0 mb-4 col-12 flex flex-column xl:flex-row gap-3 xl:gap-6">
                                <div class="flex align-items-center">
                                    <p-radioButton [value]="'hourly'" [(ngModel)]="selectedRole.payType"
                                        inputId="traditional" (click)="onChangeHourlyRate()" />
                                    <label for="traditional" class="ml-2">
                                        Hourly
                                    </label>
                                </div>
                                <div class="flex align-items-center">
                                    <p-radioButton [value]="'salary'" [(ngModel)]="selectedRole.payType"
                                        inputId="presumptively" (click)="onChangeHourlyRate()" />
                                    <label for="presumptively" class="ml-2">
                                        Salary
                                    </label>
                                </div>
                            </div>
                        </div>
                        }
                        <div
                            class="count flex align-items-center gap-1 md:gap-2 lg:gap-2 justify-content-between md:justify-content-start">
                            <p-checkbox [(ngModel)]="selectedRole.overtimeSelected" (click)="toggleOvertime()"
                                [disabled]="selectedRole.disableOvertime || savingHourlyRate" id="overtime"
                                value="selectedRole.overtimeSelected" binary="true" />
                            <label for="overtime"
                                class="white-space-nowrap font-bold text-sm ml-2 mr-auto">Overtime</label>
                            @if(selectedRole.overtimeSelected || savingHourlyRate){
                            <span class="white-space-nowrap font-bold text-sm">$ </span>
                            <p-inputNumber [(ngModel)]="selectedRole.overTime" [style]="{'width': '4rem'}"
                                styleClass="slider-style" [showButtons]="true" buttonLayout="vertical"
                                spinnerMode="vertical" inputId="vertical" [min]="selectedRole.overTimeMin"
                                decrementButtonClass="p-button-text" incrementButtonClass="p-button-text"
                                incrementButtonIcon="pi pi-chevron-up" decrementButtonIcon="pi pi-chevron-down"
                                [disabled]="!selectedRole.overtimeSelected || savingHourlyRate"
                                (onInput)="onChangeOvertime()" />
                            <span class="font-bold text-sm">
                                /hr
                            </span>
                            }
                            @if(showOvertimeSaveButton){
                            <p-button (click)="updateOvertime(selectedRole.overTime)" [rounded]="true" icon="pi pi-save"
                                size="large" />
                            }
                        </div>
                    </div>
                </div>
                }
                <div class="grid">
                    <div class="col-12 xl:col-3 my-auto">
                        <p-iconField iconPosition="right">
                            <p-inputIcon styleClass="pi pi-search" />
                            <input type="text" pInputText placeholder="Search by name or email"
                                [(ngModel)]="searchValue" (ngModelChange)="searchReviewers()" />
                        </p-iconField>
                        @if(showReviewerSearchError){
                        <div class="p-error ">Enter at least 3 characters.</div>
                        }
                    </div>
                    <div class="p-0 col-12 md:col-12 sm:col-12">
                        <ul class="chips">
                            @if(searchValue){
                            <li><p-chip [label]="searchValue" (onRemove)="onRemoveChip()" [removable]="true" /></li>
                            }
                            @if(selectedFilter && selectedFilter.name !== 'None'){
                            <li><p-chip [label]="selectedFilter.name" (onRemove)="onFilterChange(categories[0])"
                                    [removable]="true" /></li>
                            }
                        </ul>
                    </div>

                    <div class="col-12 xl:col ml-0 lg:ml-auto flex align-items-center gap-4 justify-content-end">
                        <div class="flex">
                            <p-button [link]="true" styleClass="flex align-items-center gap-2 p-1"
                                (click)="filterOption.toggle($event)">
                                <span class="pi pi-filter text-base text-black-alpha-60"></span>
                                <span class="text-sm text-black-alpha-90 hidden lg:flex">FILTER</span>
                                <span class="pi pi-chevron-down text-sm text-black-alpha-90"></span>
                            </p-button>
                            @if(selectedFilter && selectedFilter.name !== 'None'){
                            <p-badge severity="primary" class="badge"></p-badge>
                            }
                        </div>
                        <p-button [link]="true" styleClass="flex align-items-center gap-2"
                            (click)="sortOption.toggle($event)">
                            <span class="material-symbols-outlined text-base text-black-alpha-60">sort</span>
                            <span class="text-sm text-black-alpha-90 hidden lg:flex">SORT</span>
                            <span class="pi pi-chevron-down text-sm text-black-alpha-90"></span>
                        </p-button>
                        @if(selectedSortOption.length && selectedSortOption[0].key){
                        <p-button [link]="true" styleClass="flex align-items-center gap-2" (click)="toggleSortOrder()">
                            @if(!selectedSortOrder){<span
                                class="pi pi-sort-amount-down text-sm text-black-alpha-90"></span>}
                            @if(selectedSortOrder){<span
                                class="pi pi-sort-amount-up-alt sort-button-active text-primary text-sm"></span>}
                            <span class="text-sm text-black-alpha-90 hidden lg:flex">ORDER</span>
                        </p-button>}
                    </div>
                    <div class="col-12 my-3">
                        @if(reviewers.length){
                        <p-table [value]="reviewers" styleClass="p-datatable-sm p-datatable-sm--normal" [columns]="cols"
                            [(selection)]="selectedReviewers" [reorderableColumns]="false"
                            [tableStyle]="{'min-width': '50rem'}">
                            <ng-template pTemplate="header" let-columns>
                                <tr>
                                    <th style="width: 3%">
                                        <p-tableHeaderCheckbox />
                                    </th>
                                    @for (col of columns; track $index) {
                                    <th style="width: 8%;" pReorderableColumn>
                                        {{col.header}}
                                    </th>
                                    }
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-rowData let-columns="columns" let-index="rowIndex"
                                let-reviewer>
                                <tr [pReorderableRow]="index">
                                    <td>
                                        <p-tableCheckbox [value]="reviewer" />
                                    </td>
                                    <td><span class="cursor-pointer"
                                            (click)="openReviewerDialog(reviewer._id)">{{reviewer.fullName }}</span>
                                    </td>
                                    <td> <span class="material-symbols-outlined cursor-default" pTooltip="Favorite"
                                            tooltipPosition="top" [class.vibrant-pink]="reviewer.isFavourite"
                                            [class.light-grey]="!reviewer.isFavourite">
                                            bookmark_star
                                        </span>
                                        <span class="material-symbols-outlined cursor-default"
                                            pTooltip="Worked Together" tooltipPosition="top"
                                            [class.freshgreen]="reviewer.workedTogether"
                                            [class.light-grey]="!reviewer.workedTogether">
                                            group
                                        </span>

                                    </td>
                                    <td class=""> 
                                        @if(isPwcEnabled){
                                            <p-rating class="cursor-default" [(ngModel)]="reviewer.userRatings"
                                                [readonly]="true" [cancel]="false" />
                                        } @else {

                                            <p-rating class="cursor-default" [(ngModel)]="reviewer.averageRating"
                                                [readonly]="true" [cancel]="false" />
                                        }
                                    </td>
                                    <td>{{ reviewer.projectsTogether }}</td>
                                    @if(category === 'workedTogetherReviewers' || category === 'conflicted' || category
                                    === 'firedQuit'){
                                    <td> {{reviewer.details}}</td>
                                    }
                                    @if(category === 'occupied'){
                                    <td> {{reviewer.projectName}}</td>
                                    @if(reviewer.details.positions.length === 1){
                                    <td> {{reviewer.details.message}}</td>
                                    }
                                    @else{
                                    <td>
                                        <p-button class="mt-2" size="small" [plain]="true"
                                            (onClick)="showMoreInfo($event, reviewer.details.positions)">Show
                                            more</p-button>
                                    </td>
                                    }
                                    }
                                    <td>
                                        <div class="wrap-actions flex align-items-center gap-2">
                                            <p-button pTooltip="Add to shortlist" tooltipPosition="top"
                                                (click)="selectAndAddReviewerToShortList(reviewer)" [rounded]="true"
                                                [text]="true" icon="true" plain="true" icon="pi pi-plus">
                                            </p-button>
                                            <p-button pTooltip="Send Offer" tooltipPosition="top"
                                                (click)="openSendOfferDialog(reviewer)" [rounded]="true" [text]="true"
                                                icon="true" plain="true">
                                                <span class="material-symbols-outlined">
                                                    send
                                                </span>
                                            </p-button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                        @if(totalReviewersCount > reviewers.length && !lastPage){
                        <div class="mt-2">
                            <p-button [disabled]="loadingReviewers" (click)="showMoreReviewers()" pRipple
                                label="Show More" [rounded]="true" [size]="'large'" type="submit"></p-button>
                        </div>
                        }
                        } @else {
                        <span class="text-base font-bold">
                            No candidates selected.
                        </span>
                        }
                    </div>
                </div>
                <div class="flex align-items-center gap-4 justify-content-between w-full">
                    <p-button (click)="goToPrevious(2)" pRipple label="Previous" [rounded]="true" [size]="'large'"
                        type="submit"></p-button>
                    <div class="flex gap-4 align-items-center">
                        <p-button (click)="updateRole()" pRipple label="Move Selected" [rounded]="true" [size]="'large'"
                            type="submit"></p-button>
                        <p-button (click)="updateRole(true)" pRipple [disabled]="!selectedReviewers.length"
                            label="Move & Next" [rounded]="true" [size]="'large'" type="submit"></p-button>
                    </div>
                </div>
            </p-tabPanel>

            <p-tabPanel header="OFFERS QUEUE">
                @if(!selectedOfferedQueueReviewers.length && selectedShortlistedReviewers.length){
                <div class="grid">
                    <div class="col-12 md:col-6 flex justify-content-start">
                        <p-iconField iconPosition="right" class="w-full">
                            <p-inputIcon styleClass="pi pi-search" />
                            <input type="text" pInputText placeholder="Search by name or email"
                                [(ngModel)]="searchShortListed" (ngModelChange)="searchShortListedReviewers()" />
                        </p-iconField>
                    </div>
                </div>}
                <div class="grid mt-3">
                    <div class="col-12 xl:col-6 lg:col-6 flex justify-content-start">
                        @if(selectedOfferedQueueReviewers.length){
                        <div class="col-12 xl:col-12 lg:col-12 md:col-12 pl-0 xl:pl-12 lg:pl-12 md:pl-0 mb-3">
                            <div class="flex  justify-content-end">
                                <p-iconField iconPosition="right" class="w-full">
                                    <p-inputIcon styleClass="pi pi-search" />
                                    <input type="text" pInputText placeholder="Search by name or email"
                                        [(ngModel)]="searchOfferValue" (ngModelChange)="searchOfferReviewers()" />
                                </p-iconField>
                            </div>
                            <div class="flex justify-content-between align-items-center mb-4">
                                <span class="text-base font-bold">
                                    Offer Queued List
                                </span>
                                @if(showResumeButton){
                                <p-button class="mt-2" label="Resume Offers" [rounded]="true" size="large"
                                    (click)="resumeOffers()" />
                                }
                                @if(selectedRole?.offersQueueId){
                                <p-button class="mt-2" label="Pause offers" [rounded]="true" size="large"
                                    (click)="pauseOffers()" />
                                }
                            </div>
                            <div class="drag-sec">
                                <div class="grid grid-nogutter">
                                    @if(selectedOfferedQueueReviewers.length){
                                    @for(reviewer of selectedOfferedQueueReviewers; track reviewer){
                                    <div
                                        class="col-12 flex align-items-center justify-content-between px-4 surface-100 border-bottom-1 border-400">
                                        <span (click)="openReviewerDialog(reviewer._id)"
                                            class="cursor-pointer text-sm col-3">{{ reviewer.firstName }} {{
                                            reviewer.lastName }}</span>
                                        <p-rating class="mw-125 cursor-default"
                                            [(ngModel)]="reviewer.profile.averageRating" [readonly]="true"
                                            [cancel]="false" />
                                        <div class="actions flex align-items-center">
                                            <p-button (click)="openImmediateSendOfferModal(reviewer)" [rounded]="true"
                                                [text]="true" icon="true" plain="true">
                                                <span class="material-symbols-outlined">
                                                    send
                                                </span>
                                            </p-button>
                                            <p-button (click)="onRemoveOfferedQueueReviewer(reviewer)" [rounded]="true"
                                                [text]="true" icon="true" plain="true">
                                                <span class="material-symbols-outlined">
                                                    delete
                                                </span>
                                            </p-button>
                                        </div>
                                    </div>
                                    }


                                    } @else {
                                    <span class="text-base font-bold">
                                        No candidates available.
                                    </span>
                                    }
                                </div>
                            </div>
                            <div class="flex align-items-center gap-4 justify-content-between w-full my-3">


                                <div class="flex gap-4 align-items-center">
                                    @if(offersReviewersCount > selectedOfferedQueueReviewers.length ){
                                    <p-button
                                        (click)="getOfferedQueueReviewers(pagination.defaultReviewers, selectedOfferedQueueReviewers.length)"
                                        pRipple label="Show More" [rounded]="true" [size]="'large'"></p-button>
                                    }

                                    <p-button (click)="openImmediateOfferQueueSendOfferModal()" pRipple
                                        [disabled]="!selectedOfferedQueueReviewers.length" label="Send Immediate Offer"
                                        [rounded]="true" [size]="'large'"></p-button>
                                </div>
                            </div>
                        </div>
                        }
                        @if(!selectedOfferedQueueReviewers.length){
                            <div class="col-12 xl:col-12 lg:col-12 md:col-12 pr-0 xl:pr-12 lg:pr-12 md:pr-0 mb-3">
                                <div class="flex justify-content-between align-items-center mb-4">
                                    <span class="text-base font-bold">
                                        Shortlisted Candidates
                                    </span>
                                    <p-button label="send offers" [disabled]="!selectedShortlistedReviewers.length"
                                        [rounded]="true" size="small" (click)="openBulkSendOfferModal()" />
                                </div>
                                <div class="drag-sec">
                                    <div class="grid grid-nogutter drag-list">
                                        @if(selectedShortlistedReviewers.length){
                                        @for(reviewer of selectedShortlistedReviewers; track reviewer){
                                        <div
                                            class="col-12 flex align-items-center pl-3 surface-100 border-bottom-1 border-400 drag-item">
                                            <span (click)="openReviewerDialog(reviewer._id)"
                                                class="text-sm pr-0 cursor-pointer col-2">{{ reviewer.firstName }} {{
                                                reviewer.lastName }}</span>
                                            <div>
                                                <p-rating class="cursor-default p-0"
                                                    [(ngModel)]="reviewer.profile.averageRating" [readonly]="true"
                                                    [cancel]="false" />
                                            </div>
                                            <td class="col-3 text-sm flex justify-content-center gap-1"><b>Projects Worked:
                                                </b>{{ reviewer.projectsWorked }} </td>
                                            <div
                                                class="wrap-actions flex align-items-center justify-content-center col-4 p-0">
                                                @if (reviewer.isFavourite) {
                                                <div class="action-icon">
                                                    <p-button class="action-icon cursor-default" pTooltip="Favorite"
                                                        tooltipPosition="top" [rounded]="true" [text]="true" icon="true">
                                                        <span class="vibrant-pink material-symbols-outlined cursor-default">
                                                            bookmark_star
                                                        </span>
                                                    </p-button>
                                                </div>
    
                                                }@else{
                                                <div class="action-icon"></div>
                                                }
    
                                                @if (reviewer.workedTogether) {
                                                <p-button class="action-icon cursor-default" pTooltip="Worked together"
                                                    tooltipPosition="top" [rounded]="true" [text]="true" icon="true">
                                                    <span class="freshgreen material-symbols-outlined cursor-default">
                                                        group
                                                    </span>
                                                </p-button>
                                                }@else{
                                                <div class="action-icon"></div>
                                                }
                                                <p-button pTooltip="Send Offer" tooltipPosition="top"
                                                    (click)="openImmediateSendOfferModal(reviewer)" [rounded]="true"
                                                    [text]="true" icon="true" plain="true" class="action-icon">
                                                    <span class="material-symbols-outlined">
                                                        send
                                                    </span>
                                                </p-button>
                                                <p-button pTooltip="Remove Candidate" tooltipPosition="top"
                                                    (click)="onRemoveShortlistedReviewer(reviewer)" [rounded]="true"
                                                    class="action-icon" [text]="true" icon="true" plain="true">
                                                    <span class="red material-symbols-outlined">
                                                        delete
                                                    </span>
                                                </p-button>
                                                <!-- <p-button pTooltip="Drag and Drop" tooltipPosition="top" [rounded]="true"
                                                            [text]="true" icon="true" plain="true" class="action-icon ">
                                                            <span class="material-symbols-outlined text-gray-600 cursor-move">
                                                                drag_indicator
                                                            </span>
                                                        </p-button> -->
                                            </div>
                                        </div>
                                        }
    
                                        @if(shortListedReviewersCount > selectedShortlistedReviewers.length){
                                        <div>
                                            <p-button
                                                (click)="getShortListedReviewersByProjectAndRoleId(pagination.defaultReviewers, selectedShortlistedReviewers.length)"
                                                pRipple label="Show More" [rounded]="true" [size]="'large'"></p-button>
                                        </div>
                                        }
                                        } @else {
                                        <span class="text-base font-bold">
                                            No candidates available.
                                        </span>
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div class="col-12 xl:col-6 lg:col-6 flex justify-content-start">

                        <div class="col-12 xl:col-12 lg:col-12 md:col-12 pr-0 xl:pr-12 lg:pr-12 md:pr-0 mb-3">
                            <div class="flex justify-content-between align-items-center mb-5">
                                <span class="text-base font-bold">
                                    Remaining Candidates
                                </span>
                                <div class="flex align-items-center">Send offers to remaining candidates?
                                    <p-checkbox [disabled]="!autogeneratedReviewers.length" class="ml-2" binary="true"
                                        [(ngModel)]="continueSendingOfferCheckBox"
                                        (click)="toggleContinueSendingOfferOnRole($event)"></p-checkbox>
                                </div>
                            </div>
                            <div class="drag-sec">
                                <div class="grid grid-nogutter justify-content-between drag-list">
                                    @if(autogeneratedReviewers.length){
                                    @for(reviewer of autogeneratedReviewers; track reviewer){
                                    <div [class.disabled]="!continueSendingOfferCheckBox"
                                        class="col-12 flex align-items-center pl-3 surface-100 border-bottom-1 border-400 drag-item">
                                        <span
                                            (click)="continueSendingOfferCheckBox? openReviewerDialog(reviewer._id) : ''"
                                            class="text-sm pr-0 cursor-pointer col-2"
                                            [class.cursor-default]="!continueSendingOfferCheckBox">{{ reviewer.firstName
                                            }} {{ reviewer.lastName }}</span>
                                        <div>
                                            @if(isPwcEnabled){
                                                <p-rating class="cursor-default col-3" [(ngModel)]="reviewer.userRatings"
                                                    [readonly]="true" [cancel]="false" />
                                            } @else {
                                                <p-rating class="cursor-default col-3" [(ngModel)]="reviewer.averageRating"
                                                    [readonly]="true" [cancel]="false" />
                                            }
                                        </div>
                                        <div class="col-3 text-sm flex justify-content-center gap-1"><b>Projects Worked:
                                            </b>{{ reviewer.projectsWorked }} </div>
                                        <div class="flex align-items-center justify-content-center col-4 p-0">
                                            @if (reviewer.isFavourite) {
                                            <p-button [disabled]="!continueSendingOfferCheckBox"
                                                class="action-icon cursor-default" pTooltip="Favorite"
                                                tooltipPosition="top" [rounded]="true" [text]="true" icon="true">
                                                <span class="vibrant-pink material-symbols-outlined cursor-default">
                                                    bookmark_star
                                                </span>
                                            </p-button>
                                            }@else{
                                            <div class="action-icon"></div>
                                            }
                                            @if (reviewer.workedTogether) {
                                            <p-button [disabled]="!continueSendingOfferCheckBox"
                                                class="cursor-default action-icon" pTooltip="Worked together"
                                                tooltipPosition="top" [rounded]="true" [text]="true" icon="true">
                                                <span class="freshgreen material-symbols-outlined cursor-default">
                                                    group
                                                </span>
                                            </p-button>
                                            } @else{
                                            <div class="action-icon"></div>
                                            }
                                            <p-button [disabled]="!continueSendingOfferCheckBox" pTooltip="Send Offer"
                                                tooltipPosition="top" (click)="openImmediateSendOfferModal(reviewer)"
                                                [rounded]="true" [text]="true" icon="true" plain="true"
                                                class="action-icon">
                                                <span class="material-symbols-outlined">
                                                    send
                                                </span>
                                            </p-button>

                                            <!-- <p-button class="action-icon" [disabled]="!continueSendingOfferCheckBox" pTooltip="Drag and Drop" tooltipPosition="top" [rounded]="true"
                                                        [text]="true" icon="true" plain="true">
                                                        <span class="material-symbols-outlined text-gray-600 cursor-move">
                                                            drag_indicator
                                                        </span>
                                                    </p-button> -->
                                        </div>
                                    </div>
                                    }

                                    @if(!(autogeneratedReviewers.length >=
                                    totalReviewersCount-shortListedReviewersCount)){
                                    <div class="mt-3">
                                        <p-button [disabled]="loadingReviewers"
                                            (click)="showMoreAutoGeneratedReviewers()" pRipple label="Show More"
                                            [rounded]="true" [size]="'large'" type="submit"></p-button>
                                    </div>
                                    }
                                    } @else {
                                    <span class="text-base font-bold">
                                        No candidates found.
                                    </span>
                                    }
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>

<p-overlayPanel #filterOption>
    <div class="flex flex-column gap-3">
        <div *ngFor="let category of categories" class="field-checkbox">
            <p-radioButton [(ngModel)]="selectedFilter" [label]="category.name" name="group"
                (click)="onFilterChange(category)" [value]="category" />
        </div>
    </div>
</p-overlayPanel>


<p-overlayPanel #sortOption>
    <div class="flex flex-column gap-3">
        <div *ngFor="let sortOption of sortOptions" class="field-checkbox">
            <p-checkbox [(ngModel)]="selectedSortOption" [label]="sortOption.name" name="group"
                (onChange)="onSortChange(sortOption)" [value]="sortOption" />
        </div>
    </div>
</p-overlayPanel>