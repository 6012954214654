import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';

import { LoadingBarModule } from '@ngx-loading-bar/core';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmPopupModule } from 'primeng/confirmpopup';
import { AngularFireModule } from '@angular/fire/compat';
import { environment } from '../environments/environment';
@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, ToastModule, LoadingBarModule, ConfirmDialogModule, ConfirmPopupModule, AngularFireModule ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  providers: []
})
export class AppComponent implements OnInit {
  title = 'mplace';
  constructor(private primengConfig: PrimeNGConfig) { }

  ngOnInit() {
    this.primengConfig.ripple = true;
    this.storageChangeEventListner();
  }

  storageChangeEventListner(){
    window.addEventListener('storage', (event) => {
      if (event.key === 'isLoggedOut') {
        setTimeout(() => {
          window.location.href = `${environment.clientHost}login`;
        }, 2000);
      }
    })
  }
}