import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { HttpClient } from '@angular/common/http';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FileUploadModule } from 'primeng/fileupload';
import { UserService } from '../../../../../../../shared/services/user.service';
import { ToastService } from '../../../../../../../shared/services/toast.service';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { UserClientVm } from '../../../../../../../store/user/user.interface';
import { constants } from '../../../../../../../shared/constants/constants';

@Component({
  selector: 'app-manual-approval',
  standalone: true,
  imports: [TableModule, FileUploadModule, ButtonModule, ConfirmDialogModule, CommonModule, TooltipModule, InputTextModule, FormsModule, InputTextareaModule, ReactiveFormsModule],
  templateUrl: './manual-approval.component.html',
  styleUrl: './manual-approval.component.scss',
  providers: [ConfirmationService, MessageService]
})
export class ManualApprovalComponent implements OnInit {
  approvalForm!: FormGroup;
  user: UserClientVm;
  selectedDocument: File | null = null;
  reviewerId = '12345'; // Example ID, replace dynamically
  selectedCredential:any = null;
  selectedStatusSummary = { type: 'AUDIT_REVIEW', _id: '67890' };
  constants = constants;
  constructor(
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private http: HttpClient,
    private config: DynamicDialogConfig,
    private userService: UserService,
    private toast: ToastService,
    private ref: DynamicDialogRef
  ) {
    
    this.user = this.userService.getSessionUser();
     this.approvalForm = this.fb.group({
        approvedByName: [{ value: this.user.email, disabled: true }, Validators.required],
        auditTrialInformation: ['']
      });
  }

  ngOnInit(): void {
    this.reviewerId = this.config.data.reviewer._id;
    this.selectedCredential = this.config.data.credential;
  
  }

  onFileSelect(event: any): void {
    this.selectedDocument = event.files[0];
  }

  closeDialog() {
    this.ref.close();
  }
  confirmApproval(refuse: boolean): void {
    if (this.approvalForm.invalid) return;

    const action = refuse ? 'refuse' : 'approve';
    let credentialsDataLabels;
    if(this.selectedCredential.type === constants.bgCheckTypes.EDUCATION || this.selectedCredential.type === constants.bgCheckTypes.CREDENTIALS ){

        credentialsDataLabels = `${this.selectedCredential.school} - ${this.selectedCredential.description}`
    }
   
    this.confirmationService.confirm({
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Approve',  // Custom text for "Yes" button
      rejectLabel: 'Cancel',
      message: `You are about to ${action} the following ${this.selectedCredential.type === constants.bgCheckTypes.EDUCATION? 'education' : 'credential'} for ${this.config?.data.reviewer.firstName} ${this.config?.data.reviewer.lastName}? <br>
      ${credentialsDataLabels}.`,
      accept: () => this.submitApproval(refuse)
    });
  }

  async submitApproval(refuse: boolean): Promise<void> {
    const action = refuse ? 'refuse' : 'approve';
    try {
      let uploadResponse = { documentId: null };
      if (this.selectedDocument) {
        const formData = new FormData();
        formData.append('file', this.selectedDocument);
        formData.append('documentType', 'AUDIT_TRIAL');
        formData.append('reviewerId', this.reviewerId);

        const response: any = await this.http.post('/api/upload', formData).toPromise();
        uploadResponse = response.data;
      }

      const requestObject = {
        type: this.selectedCredential.type,
        typeId: this.selectedCredential._id,
        firmId: this.user.firm._id? this.user.firm._id : this.user.firm,
        approvedByName: this.approvalForm.controls['approvedByName'].value,
        auditTrialInformation: this.approvalForm.controls['auditTrialInformation'].value,
        auditDocumentId: uploadResponse.documentId,
        verifyCredentialOnly: true
      };
      if(this.selectedCredential.type === constants.bgCheckTypes.CREDENTIALS){
            if(refuse){
                await this.userService.manualRejectCredential(this.reviewerId, requestObject);
            }else{
                await this.userService.manualApproveCredential(this.reviewerId, requestObject);
            }
       }else{
            if(refuse){
                await this.userService.manualRejectEducation(this.reviewerId, requestObject);
            }else{
                await this.userService.manualApproveEducation(this.reviewerId, requestObject);
            }
      }
      
      this.closeDialog()
      this.toast.showSuccess(`Reviewer ${action}d successfully`);
    } catch (error) {
      console.error(error);
      this.closeDialog();
      this.toast.showError(`Reviewer ${action} failed`);
    }
  }
}
