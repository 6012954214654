<div class="flex justify-content-between align-items-center gap-3 mb-4">
  <h4 class="m-0">Messaging</h4>
  <p-dropdown
    [options]="roles"
    optionLabel="roleType"
    inputId="selectRole"
    placeholder="Select Role"
    [(ngModel)]="selectedRole"
    styleClass="w-full lg:w-29rem"
    (onChange)="fetchMessagesByRole()"
  />
</div>

<div class="w-full grid grid-nogutter xl:p-4 lg:p-4 p-0 pr-0 pb-0 h-page-full bg-primary-shade-4p">
  
  <div *ngIf="!roles.length">
    No Positions Available
  </div>
  
  <div  class="col-12 lg:col-4 pr-4 lg:flex flex-column" [ngClass]="{'hidden': isMobile && showList}">
    <!-- Messages -->
    <div class="flex flex-column">
      @if(discussions && discussions.length>0){
      <p-iconField   iconPosition="right">
        <p-inputIcon styleClass="pi pi-search" />
        <input [(ngModel)]="subjectSearch" type="text" pInputText placeholder="Search" (input)="getFilteredDiscussion()" />
      </p-iconField>
      }
      @if(discussions && discussions.length > 0){
        <div class="flex flex-column">
          <p-scrollPanel [style]="{ width: '100%', height: '65vh' }">
            <ul class="list-none">
              @for (msg of getFilteredDiscussion(); track $index) {
                <li [ngStyle]="{'background-color': msg.discusstionId === userInfo.currentSelectedDiscussion ? 'rgba(95, 72, 218, 0.08)' : ''}"
                class="flex gap-2 border-bottom-1 surface-border py-3 cursor-pointer"
                (click)="getThreadsByDiscussion(msg.discusstionId, msg.createdBy)">
                  <div>
                    <p-avatar
                      icon="pi pi-user"
                      styleClass="mr-2"
                      size="large"
                      [style]="{
                        'background-color': '#EFF3FF80',
                        color: '#2a1261'
                      }"
                      shape="circle"
                    />
                  </div>
                  <div class="flex flex-column gap-2 w-full">
                    <div class="flex justify-content-between align-items-center font-bold">
                      <span class="flex">{{msg.subject}}</span>
          
                      <div class="flex">
                        
                        <span class="text-xs pr-10">{{getFormattedTime(msg.lastMessageDate)}}</span>
                      </div>
                    </div>
                    
                    <div class="flex flex-row text-black-alpha-80 justify-content-between">
                      <span class="flex text-sm">{{msg.lastMessage}}</span> 
                      @if(msg.reviewerUnreadMessageCount && msg.reviewerUnreadMessageCount > 0){
                        <p-badge class="flex right ml-3" value="{{msg.reviewerUnreadMessageCount}}"  severity="primary"></p-badge>
                      }
                    </div>
                  </div>
                </li>
              }
            </ul>
          </p-scrollPanel>
        </div>
      }
    </div>
    <!-- Messages End -->
  </div>
  <div  class="col-12 lg:col bg-white lg:flex flex-column" [ngClass]="{'flex': !isMobile, 'hidden': isMobile && !showList}">
    @if(isMobile){
      <p-button icon="material-symbols-outlined" (click)="goBackToList()" label="View User" [rounded]="true" [text]="true" styleClass="text-black-alpha-50">
          <span class="material-symbols-outlined"> arrow_back </span>
      </p-button>
  }
    <!-- Messages Detail -->
    @if(messages.length>0){
    <div  class="pb-2 pl-4 bg-primary-shade-4p">
      <div class="flex flex-column">
        <span class="text-sm font-medium">{{messages[0].senderName}}</span>
        <span class="text-xs">Last contacted at {{messages[messages.length-1].msgDate | date : 'h:mm a'}}</span>
      </div>
    </div>
    }

    <div class="px-4">
      <div *ngIf="discussions && !discussions.length && !messages.length && !fetchingDiscussions">
        No Message Available
      </div>
      @if(messages.length>0){
      <p-scrollPanel #threadDiscussionPanel id="msg-list" [style]="{ width: '100%', height: '56vh' }">
        <ul class="list-none m-0">
          @for (msg of messages; track $index) {
            @if(msg.sender === 'FIRM') {
            <li class="flex gap-2 border-bottom-1 surface-border py-3">
              <div>
                <p-avatar
                  icon="pi pi-user"
                  styleClass="mr-2"
                  size="large"
                  [style]="{ 'background-color': '#EFF3FF80', color: '#2a1261' }"
                  shape="circle"
                />
              </div>
              <div class="flex flex-column gap-2 w-full">
                <div class="flex justify-content-between align-items-center font-bold">
                  <span class="text-primary-900">{{msg.senderName}}</span>
                </div>
                <div class="flex flex-column text-black-alpha-80">
                  <span class="text-sm" [innerHTML]="msg.content | lineBreak"></span>
                </div>
                <div class="flex justify-content-between align-items-center font-bold">
                  <span class="text-xs">{{msg.msgDate | date : 'MMM d, y h:mm a'}}</span>
                </div>
              </div>
            </li>
            } @else {
            <li class="flex gap-2 border-bottom-1 surface-border py-3">
              <div class="flex flex-column gap-2 w-full align-items-end">
                <div class="flex flex-column text-black-alpha-80">
                  <span class="text-sm" [innerHTML]="msg.content | lineBreak"></span>
                </div>
                <div class="flex justify-content-between align-items-center font-bold">
                  <span class="text-xs">{{msg.msgDate | date : 'MMM d, y h:mm a'}}</span>
                </div>
              </div>
            </li>
            }
          }
        </ul>
        @if(fetchingDiscussions){
          <ul class="list-none m-0 contact-list">            
            <li class="  px-4"  >
              <div class="text-center" >
                <p-progressSpinner [style]="{ width: '50px' }" ariaLabel="loading" />
              </div>
            </li>
          </ul>
        }
      </p-scrollPanel>
    }
    </div>
    @if(messages.length>0){
    <div 
      class="pl-4 pr-4 surface-border mt-auto flex flex-column align-items-end gap-3"
    >
      <textarea
        rows="2"
        cols="30"
        [(ngModel)]="userInfo.content"
        placeholder="Type message here..."
        (keydown.enter)="onEnterPressed($event)"
        maxlength="1000"
        pInputTextarea
        [autoResize]="true"
      ></textarea>
      <p-button
        label="Send Message"
        rounded="true"
        [disabled]="!userInfo.content"
        styleClass="teal white-space-nowrap"
        (click)="sendMessage()"
      ></p-button>
    </div>
     }
    <!-- Messages Detail -->
  </div>
</div>
