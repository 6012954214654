<div>
    
    <p-multiSelect [(ngModel)]="value" display="chip"
      [options]="rankings" optionLabel="name" appendTo="body"/>    
    
    <div class="flex justify-content-end pt-10">
      <p-button
        label="Done"
        size="large"
        [rounded]="true"
        [outlined]="true"
        (onClick)="closeDialog()"
        [ngStyle]="{'margin-right': '10px'}"      />
    </div>
</div>
  