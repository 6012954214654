<div class="grid grid-nogutter">
    <div class="p-0 col-12 mb-5">
        <h3 class="m-0 h4 mb-2">Payroll Report</h3>
    </div>
    <div class="xl:col-3 lg:col-4 col-12 lg:pr-3 xl:pr-3 pr-0 mb-3">
        <p-multiSelect [options]="projects" [(ngModel)]="selectedProjects" optionLabel="codeName" placeholder="Project" (onChange)="onProjectSelectionChange($event)" [ngClass]="{'ng-dirty ng-invalid': showProjectSelectionError}"/>
        
        @if(showProjectSelectionError){
            <span class="p-error pl-1 text-xs">This is required</span>
        }
    </div>
    <div class="xl:col-3 lg:col-4 col-12 lg:pr-3 xl:pr-3 pr-0 mb-3">
        <p-multiSelect [options]="roles" [(ngModel)]="selectedRoles" optionLabel="roleType" placeholder="Role" (onChange)="onRolesSelectionChange($event)" />
    </div>
    <div class="xl:col-3 lg:col-4 col-12 lg:pr-3 xl:pr-3 pr-0 mb-3">
        <p-dropdown [options]="timezones" [(ngModel)]="selectedTimezone" optionLabel="val" [placeholder]="isDefaultTimeZone ? 'Default Timezone' : 'Timezone'" [disabled]="isDefaultTimeZone" />
    </div>
    <div class="xl:col-3 lg:col-4 col-12 lg:pr-0 xl:pr-0 pr-0 mb-3 flex align-items-center gap-3">
        <p-checkbox 
            [(ngModel)]="isDefaultTimeZone" 
            [binary]="true" 
            inputId="default-tz"
            (onChange)="onChangeDefaultTimezoneCheck($event)" />
        <label for="default-tz">Use Default Timezone</label>
    </div>
    <div class="xl:col-3 lg:col-4 col-12 lg:pr-3 xl:pr-3 pr-0 mb-3">
        <p-floatLabel class="h-full">
            <p-calendar 
                inputId="start-date-selector"
                [maxDate]="endDate"
                [(ngModel)]="startDate" />
            <label for="start-date-selector">Start Date</label>
        </p-floatLabel>
    </div> 
    <div class="xl:col-3 lg:col-4 col-12 lg:pr-3 xl:pr-3 pr-0 mb-3">
        <p-floatLabel class="h-full">
            <p-calendar 
                inputId="end-date-selector" 
                [(ngModel)]="endDate"/>
            <label for="end-date-selector">End Date</label>
        </p-floatLabel>
    </div>
    <div class="xl:col-3 lg:col-4 col-12 lg:pr-0 xl:pr-3 pr-0 mb-3">
        <p-multiSelect [options]="reviewers" [(ngModel)]="selectedReviewers" optionLabel="fullName" placeholder="Candidate" />
    </div>
    <div class="xl:col-3 lg:col-4 col-12 lg:pr-3 xl:pr-0 pr-0 mb-3 flex justify-content-start pl-0 lg:pl-3 xl:pl-0">
        <p-button label="GO" [rounded]="true" size="large" (click)="generateReport()"/>
    </div>
 @if(payrollReports.length){
    <div class="flex gap-3 col-12 mb-5 flex-column lg:flex-row xl:flex-row" >
        <p-button label="Export PDF" [rounded]="true" size="large" (click)="pdf.toggle($event)"/>
        <p-button label="Export Summary PDF" [rounded]="true" size="large" (click)="pdfSummary.toggle($event)"/>
        <p-button label="Export CSV" [rounded]="true" size="large" (click)="csv.toggle($event)"/>
        <p-button label="Export Summary CSV" [rounded]="true" size="large" (click)="csv.toggle($event)"/>
    </div>
    <div class="flex col-12 gap-4 align-items-center justify-content-around flex-column lg:flex-row xl:flex-row">
            <div class="flex flex-column align-items-center">
                <h5 class="m-0 mb-3">Total Hours</h5>
                <p>{{summary.totalProjectHours}}</p>
            </div>
            <div class="flex flex-column align-items-center">
                <h5 class="m-0 mb-3">Total Regular Hours</h5>
                <p>{{summary.totalProjectRegularHours}}</p>
    
            </div>
            <div class="flex flex-column align-items-center">
                <h5 class="m-0 mb-3">Total Over Time Hours</h5>
                <p>{{summary.totalProjectOTHours}}</p>
            </div>
    </div>
 }
    <div class="p-0 col-12 mt-4">
        <p-table styleClass="p-datatable-sm p-datatable-sm--normal" [value]="payrollReports" [paginator]="true" [rows]="2" [tableStyle]="{ 'min-width': '50rem' }"
            [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} - {last} of {totalRecords}"
            autoLayout="true" [rows]="noOfRows">
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="reviewerName">
                        Candidate <p-sortIcon field="reviewerName" />
                    </th>
                    <th pSortableColumn="projectName">
                        Project/Role <p-sortIcon field="projectName" />
                    </th>
                    <th pSortableColumn="totalHours">
                        Total Regular Hours <p-sortIcon field="totalHours" />
                    </th>
                    <th pSortableColumn="totalOT">
                        Total OT Hours <p-sortIcon field="totalOT" />
                    </th>
                    <th pSortableColumn="payrollTimezone || selectedTimezone?.val">
                        Payroll Timezone <p-sortIcon field="payrollTimezone || selectedTimezone?.val" />
                    </th>
                    <th style="width: 8%;">
                        Actions
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-product>
                <tr>
                    <td>{{ product.reviewerName }}</td>
                    <td>{{ product.projectName }} / {{product.roleName}}</td>
                    <td>{{ product.totalRE }}</td>
                    <td>{{ product.totalOT }}</td>
                    <td>{{ product.payrollTimezone  || selectedTimezone?.val }}</td>
                    <td>
                        <div class="wrap-actions flex align-items-center gap-2">
                            <p-button
                            [rounded]="true"
                            [text]="true"
                            icon="true"
                            (onClick)="downloadDetailPayroll(product)"
                            pTooltip="Download"
                            tooltipPosition="top"
                            >
                                <span class="material-symbols-outlined">download</span>
                            </p-button>
                            <p-button 
                                type="button" 
                                pRipple 
                                [pRowToggler]="product" 
                                [text]="true"
                                [rounded]="true" 
                                [plain]="true" 
                                [icon]="product.showDetail ? 'pi pi-chevron-up' : 'pi pi-chevron-down'" 
                                class="row-toggler"
                                (click)="showDetail(product)" />
                        </div>
                    </td>
                </tr>
                @if(product.showDetail){
                    <tr class="sub-grid">
                        <td class="wrapper-td surface-100" colspan="6">
                            @for (item of product.details | keyvalue: originalOrder; track item) {
                                <div>
                                    <span class="block font-bold my-2 text-left text-sm w-full">{{item.key}}</span>
                                    <p-table [value]="item.value" [tableStyle]="{ 'min-width': '50rem' }"  styleClass="p-datatable-md p-datatable-md--normal" autoLayout="true"> 
                                        <ng-template pTemplate="header">
                                            <tr>
                                                <th style="width: 5%;" pSortableColumn="code">
                                                    S.No
                                                </th>
                                                <th style="width: 15%;" pSortableColumn="name">
                                                    Time In
                                                </th>
                                                <th style="width: 15%;" pSortableColumn="date">
                                                    Time Out
                                                </th>
                                                <th style="width: 8%;" pSortableColumn="hours">
                                                    Duration
                                                </th>
                                                <th style="width: 15%;">
                                                    Notes
                                                </th>
                                                <th style="width: 8%;" pSortableColumn="timezone">
                                                    Timezone
                                                </th>
                                                <th style="width: 8%;" pSortableColumn="timezone">
                                                    Actions
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-productDetails let-rowIndex="rowIndex">
                                            <tr>
                                                <td>
                                                    {{rowIndex + 1}}
                                                </td>
                                                <td>
                                                    {{productDetails.start}}
                                                </td>
                                                <td>
                                                    {{productDetails.end}}
                                                </td>
                                                <td>
                                                    {{toHours(productDetails.duration || 0) }}
                                                </td>
                                                <td>
                                                    {{productDetails.notes || 'N/A'}}
                                                </td>
                                                <td>
                                                    {{productDetails.timezoneAbbrevation}}
                                                </td>
                                                <td>
                                                    <div class="wrap-actions flex align-items-center gap-2" style="float: right;">
                                                        <p-button pTooltip="Edit" tooltipPosition="top" [rounded]="true" [text]="true" icon="true" plain="true" (click)="editTimeSheet(productDetails, product)">
                                                            <span class="material-symbols-outlined">
                                                                edit
                                                            </span>
                                                        </p-button>
                                                        <p-button pTooltip="Delete" tooltipPosition="top" [rounded]="true" [text]="true" icon="true" plain="true" (click)="deleteInvoice($event, productDetails)">
                                                            <span class="red material-symbols-outlined">
                                                                delete
                                                            </span>
                                                        </p-button>
                                                    </div>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                </div>
                            }
                        </td>
                    </tr>
                }
            </ng-template>
        </p-table>
    </div>
</div>

<p-overlayPanel #pdf>
    <div class="flex flex-column gap-3">
        @for(option of reportOptions; track option) {
            <p-button [label]="option.label" [rounded]="true" size="small" (click)="exportPDF(option.key, 'pdf')" [text]="true"/>
        }
    </div>
</p-overlayPanel>
<p-overlayPanel #pdfSummary>
    <div class="flex flex-column gap-3">
        @for(option of reportOptions; track option) {
            <p-button [label]="option.label" [rounded]="true" size="small" (click)="exportPDFSummary(option.key, 'pdf')" [text]="true"/>
        }
    </div>
</p-overlayPanel>
<p-overlayPanel #csv>
    <div class="flex flex-column gap-3">
        @for(option of reportOptions; track option) {
            <p-button [label]="option.label" [rounded]="true" size="small" (click)="exportCSV(option.key, 'csv')" [text]="true"/>
        }
    </div>
</p-overlayPanel>

<p-dialog header="Timesheet Editor" [modal]="true" [(visible)]="showTimeSheetEditor"
    [draggable]="false" styleClass="md:w-6 w-full">
    @if(showTimeSheetEditor){
    <app-timesheet-editor (closeModal)="closeModal($event)" [data]="selectedTimeSheet"
    [isEdit]="true" [roles]="role" [reviewerName]="reviewerName" [from]="'firm-manager'"
    [selectedRole]="selectedRole"></app-timesheet-editor>
    }
</p-dialog>