<div>
  <form [formGroup]="createForm">
    <div class="grid grid-nogutter">
      <div class="col-12 pb-3 p-0">
        <p-floatLabel>
          <input formControlName="name" type="text" pInputText />
          <label for="name">Name<span class="required">*</span></label>
        </p-floatLabel>
      </div>
      <div class="col-12 pb-3 p-0">
        <p-floatLabel>
          <input formControlName="description" type="text" pInputText class="h-5rem" />
          <label for="description">Description</label>
        </p-floatLabel>
      </div>
    </div>
    <div class="flex justify-content-end">
      <p-button label="CANCEL" size="large" [rounded]="true" [outlined]="true" (onClick)="closeDialog()"
        [ngStyle]="{'margin-right': '10px'}" />
      <p-button styleClass="freshgreen-background" label="{{buttonText}}" size="large" [disabled]="!createForm.valid"
        [rounded]="true" (onClick)="onSubmit()" />
    </div>
  </form>
</div>