import { Component, OnInit } from '@angular/core';
import {
    DialogService,
    DynamicDialogConfig,
    DynamicDialogModule,
    DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { FloatLabelModule } from 'primeng/floatlabel';
import { CommonModule } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { UserService } from '../../../../../../../../shared/services/user.service';
import { InputNumberModule } from 'primeng/inputnumber';
import { RadioButtonModule } from 'primeng/radiobutton';

@Component({
    selector: 'pay-rate-selector-modal',
    providers: [DialogService],
    standalone: true,
    imports: [
        FloatLabelModule,
        DialogModule,
        ButtonModule,
        FormsModule,
        CommonModule,
        DynamicDialogModule,
        InputNumberModule,
        RadioButtonModule,
    ],
    templateUrl: './pay-rate-selector-modal.component.html',
    styleUrl: './pay-rate-selector-modal.component.scss'
})
export class PayRateSelectorModalComponent implements OnInit {

    minValue: string = '';
    maxValue: string = '';
    payRateType: string = '';
    sessionUser: any = null;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private userService: UserService
    ) { }

    ngOnInit() {
        this.sessionUser = this.userService.getSessionUser();
        this.minValue = this.config?.data?.minPayRateValue;
        this.maxValue = this.config?.data?.maxPayRateValue;
        this.payRateType = this.config?.data?.selectedType;
    }

    closeDialog() {
        if(!this.minValue || !this.maxValue){
            this.ref.close(false);
        }
        else{
            this.ref.close({type: this.payRateType, minValue: this.minValue, maxValue: this.maxValue});
        }
    }
}
