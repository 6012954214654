<div class="grid grid-nogutters edit-template" [ngClass]="{'template-container': isViewOnly}">
    <div style="min-height:60vh; justify-content: center;" [ngClass]="{'doc-center': type === 'view'}" layout="row" class="container">
        @if(!isViewOnly){
            <div>
                <div class="">
                    <div class="wrap-list">
                        <ul>
                            <li>
                                <a (click)="addElement('signHere')">
                                    <span>
                                        <svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                                            <path d="M16 16H0v-1h16zM13.8.3c.4.4.4 1 0 1.4L11 4.5c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4L12.4.3c.3-.4 1-.4 1.4 0zM1.4 9.9L0 14.1l4.2-1.4z"></path>
                                            <path d="M4.9 12L11 5.9l.5-.5.9-.9c.4-.4.4-1 0-1.4L11 1.7c-.4-.4-1-.4-1.4 0l-.9.9-6.6 6.6L4.9 12z"></path>
                                        </svg>
                                    </span> 
                                    <span>
                                        Signature
                                    </span>
                                </a>
                            </li>
                            <li><a (click)="addElement('initialHere')"><span><svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                                <path d="M0 14h16v2H0v-2zM3.72.74c.79 0 1.42.13 1.91.39s.86.62 1.13 1.1c.26.48.44 1.05.54 1.73.09.67.14 1.42.14 2.25 0 .99-.06 1.85-.18 2.58s-.33 1.33-.62 1.81c-.29.47-.69.83-1.18 1.06-.5.22-1.13.34-1.88.34H0V.74h3.72zm-.44 9.59c.4 0 .72-.07.96-.21.24-.14.43-.36.58-.68.14-.31.23-.73.28-1.24.05-.51.07-1.16.07-1.92 0-.64-.02-1.2-.06-1.69-.04-.49-.13-.89-.26-1.21-.13-.32-.33-.56-.59-.72-.26-.16-.6-.24-1.03-.24h-.96v7.91h1.01zm7.5-1.52c0 .26.02.5.06.72.04.22.12.4.24.54.12.15.27.26.46.35.19.08.44.13.73.13.35 0 .66-.11.94-.34.28-.23.42-.58.42-1.05 0-.25-.03-.47-.1-.65a1.35 1.35 0 00-.34-.5c-.16-.15-.37-.28-.62-.4a7.91 7.91 0 00-.95-.37c-.5-.17-.94-.35-1.31-.55-.37-.2-.68-.43-.92-.7-.25-.28-.43-.59-.55-.94-.11-.35-.17-.76-.17-1.22 0-1.11.31-1.94.93-2.49.62-.55 1.47-.82 2.55-.82.5 0 .97.05 1.39.17.42.12.79.29 1.1.54.31.25.55.56.73.95.17.38.26.84.26 1.38v.32h-2.17c0-.54-.09-.95-.28-1.24-.19-.29-.5-.43-.95-.43-.25 0-.46.04-.63.11a1.011 1.011 0 00-.61.71c-.04.16-.06.32-.06.49 0 .35.07.64.22.87.15.24.46.45.95.65l1.75.76c.43.19.78.39 1.06.59.27.21.49.43.65.66.16.24.28.5.34.78.07.27.1.59.1.93 0 1.19-.34 2.05-1.03 2.59s-1.65.81-2.88.81c-1.28 0-2.2-.28-2.75-.84-.55-.56-.83-1.36-.83-2.4v-.44h2.27v.33z"></path>
                            </svg></span> Initial</a></li>
                            <li><a (click)="addElement('dateSigned')"><span><svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                                <path d="M14 2H2C.9 2 0 2.9 0 4v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM2 14V6h12v8H2zM3 0h2v2H3zm8 0h2v2h-2z"></path>
                            </svg></span> Date Signed</a></li>
                        </ul>
                    </div>
                    <div class="wrap-list">
                        <ul>
                            <li><a id="textTab_fullName" draggable="true" (click)="addElement('textTab_fullName')"><span><svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                                <path d="M8 8a4 4 0 110-8 4 4 0 010 8zm0 1c4 0 8 2 8 4v3H0v-3c0-2 4-4 8-4z"></path>
                            </svg></span> Name</a></li>
                            <li><a (click)="addElement('textTab_email')"><span><svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                                <path d="M8 7.8L16 3c0-1.1-.9-2-2-2H2C.9 1 0 1.9 0 3v.1l8 4.7z"></path>
                                <path d="M8 10.2L0 5.4V13c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V5.3l-8 4.9z"></path>
                            </svg></span>Email</a></li>
                        </ul>
                    </div>
                    <div class="wrap-list">
                        <ul>
                            <li><a (click)="addElement('textTab_custom')"><span><svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                                <path d="M7 2h2v12H7z"></path>
                                <path d="M2 2h12v2H2zm3 10h6v2H5z"></path>
                                <path d="M2 2h2v4H2zm10 0h2v4h-2z"></path>
                            </svg></span> Text</a></li>
                            <li><a (click)="addElement('checkBoxTab')"><span><svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                                <path d="M13.7 2c.2 0 .3.1.3.3v11.4c0 .2-.1.3-.3.3H2.3c-.2 0-.3-.1-.3-.3V2.3c0-.2.1-.3.3-.3h11.4m0-2H2.3C1 0 0 1 0 2.3v11.4C0 15 1 16 2.3 16h11.4c1.3 0 2.3-1 2.3-2.3V2.3C16 1 15 0 13.7 0z"></path>
                                <path d="M13 5.5l-1.4-1.4L6.7 9 4.4 6.8 3 8.2l3.7 3.7z"></path>
                            </svg></span> Checkbox</a></li>
                            <li><a (click)="addElement('radioTab')"><span><svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                                <path d="M8 2c3.3 0 6 2.7 6 6s-2.7 6-6 6-6-2.7-6-6 2.7-6 6-6m0-2C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z"></path>
                                <circle cx="8" cy="8" r="3.5"></circle>
                            </svg></span> Radio</a></li>
                        </ul>
                    </div>
                    <div class="wrap-list">
                        <ul>
                            <li><a (click)="addElement('signerAttachment')"><span><svg class="SVGInline-svg" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" focusable="false">
                                <path d="M8 14.8c-1.6 1.6-4.2 1.6-5.8 0S.6 10.6 2.2 9L10.3.9c1.2-1.2 3.1-1.2 4.2 0s1.2 3.1 0 4.2l-7 7c-.7.7-1.9.7-2.7 0s-.7-1.9 0-2.7L11.3 3l.8.8-6.5 6.5c-.3.3-.3.8 0 1.1s.8.3 1.1 0l7-7c.7-.7.7-1.9 0-2.7s-1.9-.7-2.7 0L3 9.8C1.8 11 1.8 12.9 3 14s3.1 1.2 4.2 0l7.5-7.5.8.8L8 14.8z"></path>
                            </svg></span> Attachment</a></li>
                        </ul>
                    </div>
                </div>
                <img id="extracted" >
            </div>
        }
        <div class="content">
            <div class="wrap-item">
                <div>
                    <button id="prev">Previous</button>
                    <button id="next">Next</button>
                    &nbsp; &nbsp;
                    <span>Page: <span id="page_num" #pageNum></span> / <span #pageCount id="page_count"></span></span>
                </div>
                <div id="template-parent" #templateParent class="template-parent-class">
                    <canvas id="the-canvas" #theCanvas class="canvas-content"  style="border:1px solid lightGray"></canvas>
                </div>
            </div>
            
        </div>
        @if(!isViewOnly){
            <div style="padding-left:5px; width: 300px;">
                <div class="wraper-side-wrap">
                    <div class="upper-fields">
                        <ul>
                            @if(templateFields?.[pagenumber]?.[selectedObject]?.[selectedObjectId]){
                                <li><input [disabled]="getDisabledStatus()" [(ngModel)]="templateFields[pagenumber][selectedObject][selectedObjectId].required" type="checkbox"><label for="">Required</label></li>
                                <li><input [disabled]="getDisabledStatus()" [(ngModel)]="templateFields[pagenumber][selectedObject][selectedObjectId].editable" type="checkbox"><label for="">Editable</label></li>
                                @if(selectedObject === 'radioTab'){
                                    <li>
                                        <input [disabled]="getDisabledStatus()" 
                                        (change)="checkRadioAlignement()"
                                        [(ngModel)]="templateFields[pagenumber][selectedObject][selectedObjectId].alignHorizontal" 
                                        type="checkbox">
                                        <label for="">Align Horizontal</label>
                                    </li>
                                }
                            }
                        </ul>
                    </div>
                    <div id="wrapper">
                    
                        <ul>
                            <p-accordion class="display-none">
                                <p-accordionTab header="Formatting">
                                    <p>
                                        <input type="number">
                                        <label for="">Scale %</label>
                                    </p>
                                </p-accordionTab>
                            </p-accordion>
                            
                            @if(templateFields?.[pagenumber]?.[selectedObject]?.[selectedObjectId]){ 
                                <p-accordion>
                                    <p-accordionTab header="Tab Label">
                                        <p>
                                            
                                            <input [disabled]="true" [(ngModel)]="templateFields[pagenumber][selectedObject][selectedObjectId].tabLabel" type="text">
                                        </p>
                                    </p-accordionTab>
                                </p-accordion>
                                <p-accordion>
                                    <p-accordionTab header="Data Label">
                                        <p>
                                            <input [disabled]="getDisabledStatus()" [(ngModel)]="templateFields[pagenumber][selectedObject][selectedObjectId].tabName" type="text">
                                        </p>
                                    </p-accordionTab>
                                </p-accordion>
                                @if(selectedObject ==='textTab_custom'){
                                    <p-accordion>
                                        <p-accordionTab header="Size (Height / Width)">
                                            <span for="">Height</span>
                                            <p>
                                                <input [disabled]="getDisabledStatus()" (blur)="onBlurTextBoxHeight()" [(ngModel)]="templateFields[pagenumber][selectedObject][selectedObjectId].height" type="number">
                                            </p>
                                            <span for="">Width</span>
                                            <p>
                                                <input [disabled]="getDisabledStatus()" (blur)="onBlurTextBoxWidth()" [(ngModel)]="templateFields[pagenumber][selectedObject][selectedObjectId].width" type="number">
                                            </p>
                                        </p-accordionTab>
                                    </p-accordion>
                                }
                                @if(selectedObject ==='checkBoxTab'){
                                    <p-accordion>
                                        <p-accordionTab header="Group Label">
                                            <p>
                                                <input [disabled]="getDisabledStatus()" [(ngModel)]="templateFields[pagenumber]['tabGroup'][templateFields[pagenumber][selectedObject][selectedObjectId].groupId].tabLabel" type="text">
                                            </p>
                                        </p-accordionTab>
                                    </p-accordion>
                                    
                                }
                                @if(selectedObject ==='checkBoxTab'){
                                    <p-accordion>
                                        <p-accordionTab header="Group Tooltip">
                                            <p>
                                                <textarea [disabled]="getDisabledStatus()" [(ngModel)]="templateFields[pagenumber]['tabGroup'][templateFields[pagenumber][selectedObject][selectedObjectId].groupId].groupTooltip" ></textarea>
                                            </p>
                                        </p-accordionTab>
                                    </p-accordion>
                                }
                                @if(selectedObject ==='checkBoxTab'){
                                    <p-accordion>
                                        <p-accordionTab header="Validation">
                                            <p>
                                                <select style="width: 70%;" [disabled]="getDisabledStatus()" [(ngModel)]="templateFields[pagenumber]['tabGroup'][templateFields[pagenumber][selectedObject][selectedObjectId].groupId].validationType" type="text">
                                                    @for(option of validationOptions; track option){
                                                        <option>{{option.label}}</option>
                                                    }
                                                </select>
                                            </p>
                                            @if(templateFields[pagenumber]['tabGroup'][templateFields[pagenumber][selectedObject][selectedObjectId].groupId].validationType !== 'SelectARange'){
                                                <p>
                                                    <select style="width: 70%;" [disabled]="getDisabledStatus()" [(ngModel)]="templateFields[pagenumber]['tabGroup'][templateFields[pagenumber][selectedObject][selectedObjectId].groupId].validationCount" type="text">
                                                        <option value="0" selected >0</option>
                                                        @for(opt of getCheckBoxesByGroupId(templateFields[pagenumber][selectedObject][selectedObjectId]); track $index){
                                                            <option [value]="$index+1">{{$index+1}}</option>
                                                        }
                                                    </select>
                                                </p>
                                            }
                                            @if(templateFields[pagenumber]['tabGroup'][templateFields[pagenumber][selectedObject][selectedObjectId].groupId].validationType === 'SelectARange'){
                                                <p>
                                                    Min
                                                    <select style="width: 20%; display: inline-block;" [disabled]="getDisabledStatus()" [(ngModel)]="templateFields[pagenumber]['tabGroup'][templateFields[pagenumber][selectedObject][selectedObjectId].groupId].validationCountMin" type="text">
                                                        <option value="0" selected >0</option>
                                                        @for(opt of getCheckBoxesByGroupId(templateFields[pagenumber][selectedObject][selectedObjectId]); track $index){
                                                            <option [value]="$index+1">{{$index+1}}</option>
                                                        }
                                                    </select>
                                                - Max
                                                    <select style="width: 20%; display: inline-block;" [disabled]="getDisabledStatus()" [(ngModel)]="templateFields[pagenumber]['tabGroup'][templateFields[pagenumber][selectedObject][selectedObjectId].groupId].validationCountMax" type="text">
                                                        <option value="0" selected >0</option>
                                                        @for(opt of getCheckBoxesByGroupId(templateFields[pagenumber][selectedObject][selectedObjectId]); track $index){
                                                            <option [value]="$index+1">{{$index+1}}</option>
                                                        }
                                                    </select>
                                                </p>
                                            }
                                        </p-accordionTab>
                                    </p-accordion>
                                }
                                @if(selectedObject ==='checkBoxTab'){
                                    <p-accordion>
                                        <p-accordionTab header="Conditional Fields">
                                            @for(condField of templateFields[pagenumber][selectedObject][selectedObjectId]['conditionalFields']; track condField){
                                                <div (mouseenter)="callMouseOver(condField.selectedElement)" (mouseleave)="callMouseOut()" style="background-color: lightgrey;
                                                padding: 5px;">
                                                    <p>
                                                    <select   style="width: 150px; display: block;" [disabled]="getDisabledStatus()" [(ngModel)]="condField.selectedElement" type="text">
                                                        @for(pageElement of getSelectableElements(templateFields[pagenumber]); track pageElement){
                                                            <option [value]="pageElement.tabLabel">{{pageElement.tabLabel}}</option>
                                                        }
                                                    </select>
                                                    Select the fields to show when checkbox is
                                                    <select [disabled]="getDisabledStatus()" [(ngModel)]="condField.checked" type="text">
                                                        <option  value="checked">checked</option>
                                                        <option  value="unchecked">unchecked</option>
                                                    </select>
                                                    <i style="right: 16px;
                                                    bottom: 25px;
                                                    color: black !important;
                                                    cursor: pointer;" (click)="removeConditionalField(templateFields[pagenumber][selectedObject][selectedObjectId]['conditionalFields'],$index)" class="fa fa-close"></i>
                                                    </p>
                                                </div>
                                            }
                                            <p><p-button class="secondary" (click)="addConditinalField(templateFields[pagenumber][selectedObject][selectedObjectId]['conditionalFields'])">+ Add Conditonal Field</p-button></p>
                                            
                                        </p-accordionTab>
                                    </p-accordion>
                                }
                            }
                            <li style="display:none">
                                <input type="checkbox" checked>
                                <i></i>
                                <h2>Tooltip</h2>
                                <p>
                                    <textarea name="" id="" ></textarea>
                                </p>
                            </li>
                            <li style="display:none">
                                <input type="checkbox" checked>
                                <i></i>
                                <h2>Location</h2>
                                <p>
                                    <input type="number">
                                    <label for="">Pixels from Left</label>
                                    <input type="number">
                                    <label for="">Pixels from Top</label>
                                    <input type="submit" value="Set Up">
                                    <label for="">AutoPlace</label>
                                </p>
                            </li>
                            <li style="display:none">
                                <input type="checkbox" checked>
                                <i></i>
                                <h2>Add Text</h2>
                                <p>
                                    <textarea name="" id="" ></textarea>
                                    <input type="number">
                                    <label for="">Character Limit</label>
                                </p>
                            </li>
    
                        </ul>
                    </div>
                </div>
            </div>
        }
    </div>
    <div style="width: 100%;" class="space-bottom-fifteen space-top-thirty">
        <section layout="row" layout-sm="column" layout-align="space-between center" class="sm-controls flex justify-content-end gap-5">
            <p-button class="btn btn-lg btn-secondary" [rounded]="true"
                    (click)="backToDocList()">Go Back
            </p-button>
            <p-button 
                    [ngClass]="{'display-none': isViewOnly}"
                    styleClass="freshgreen-background"
                    type="submit"
                    [rounded]="true"
                    (click)="isEditMode ? openUpdateConfirmationModal() : openSaveConfirmationModal()">
                <span class="space-l-r-twnty">Save Template</span>
            </p-button>
        </section>
    </div>
</div>

<p-dialog 
    [modal]="true" 
    draggable="false"
    [(visible)]="saveConfirmationModalVisible" 
    [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" 
    [style]="{ width: '660px' }" >

    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-bold white-space-nowrap">
                Confirm Document Completion
            </span>
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div class="flex flex-column gap-2">
            <span class="text-sm">{{saveConfirmationModalContent}}</span>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <p-button label="NO" size="large" [rounded]="true" (onClick)="saveTemplateConfig()" severity="danger" />
        <p-button label="YES" size="large" [rounded]="true" (onClick)="saveTemplateConfig(true)" styleClass="freshgreen-background"/>
    </ng-template>
</p-dialog>

<p-dialog 
    [modal]="true" 
    draggable="false"
    [(visible)]="updateConfirmationModalVisible" 
    [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" 
    [style]="{ width: '660px' }" >

    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-bold white-space-nowrap">
                {{updateConfirmationModalContent}}
            </span>
        </div>
    </ng-template>

    <ng-template pTemplate="footer">
        <p-button label="NO" size="large" [rounded]="true" (onClick)="updateTemplateConfig()"/>
        <p-button label="YES" size="large" [rounded]="true" (onClick)="updateTemplateConfig(true)"/>
    </ng-template>
</p-dialog>
