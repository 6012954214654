import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ScrollPanel, ScrollPanelModule } from 'primeng/scrollpanel';
import { FirebaseMessagingService } from '../../../shared/services/firebase-messaging.service';
import { ProjectService } from '../../../shared/services/project.service';
import { UserService } from '../../../shared/services/user.service';
import { TreeNode } from 'primeng/api';
import { AngularFireModule } from '@angular/fire/compat';
import { FormsModule } from '@angular/forms';
import { ChipsModule } from 'primeng/chips';
import { DialogModule } from 'primeng/dialog';
import { FloatLabelModule } from 'primeng/floatlabel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TreeModule } from 'primeng/tree';

import { combineLatest, lastValueFrom, map, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { ReviewerService } from '../../../shared/services/reviewer.service';
import { FirebaseMessagingObject } from '../reviewer-dashboard.interface';
import moment from 'moment';
import { MessagesService } from '../../../shared/services/messages-service.service';
import { BadgeModule } from 'primeng/badge';
import { StorageService } from '../../../shared/services/storage.service';
import { ToastService } from '../../../shared/services/toast.service';
import { EmittersService } from '../../../shared/services/emitters.service';
import { Reviewer } from '../../../shared/interfaces/reviewer.interface';
import { PipesModule } from '../../../shared/pipes/pipes.module';
import { constants } from '../../../shared/constants/constants';

@Component({
  selector: 'app-messages',
  standalone: true,
  imports: [
    InputSwitchModule, 
    BadgeModule,
    FormsModule, 
    IconFieldModule, 
    InputIconModule, 
    InputTextModule, 
    TreeModule,
    CommonModule,
    ButtonModule,
    DropdownModule,
    AvatarModule,
    AvatarGroupModule,
    InputTextareaModule,
    ScrollPanelModule,
    FloatLabelModule,
    DialogModule,
    ChipsModule,
    AngularFireModule,
    ProgressSpinnerModule,
    PipesModule
  ],
  templateUrl: './messages.component.html',
  styleUrl: './messages.component.scss'
})
export class MessagesComponent implements OnInit {
  [x: string]: any;
  checked: boolean = false;
  visible: boolean = false;
  files!: TreeNode[];
  dummy!: any[];
  roleId!: any;
  roles: any = []; 
  positions: any = []; 
  messages: any = [];
  userList = false;
  messagesList  = false;
  messageProjects: any = []; 
  discussionSubscriber: any =[];
  messagesSubscriber: any=[];
  discussions!: any[];
  roleDiscussions:any=[];
  projectBroadcastDiscussions:any=[];
  fetchingDiscussions: boolean = false;
  fetchingThreads: boolean = false;
  selectedRole: any = {};
  totalMessageCount = 0;
  subjectSearch = '';
  isMessageOpen: boolean = true;
  userInfo!: FirebaseMessagingObject;
  defaultDiscussionOrder: any;
  tenantSubscriber: any;
  varA$ = new BehaviorSubject<number>(0); // Example observable
  varB$ = new BehaviorSubject<number>(0); // Another example observable
  reviewer!: Reviewer;
  @ViewChild('threadDiscussionPanel') threadDiscussionPanel!: ScrollPanel;
  showList: boolean = false;
  isMobile: boolean = false;

  constructor(
    private firebaseMessagingService:FirebaseMessagingService,
    private projectService: ProjectService,
    private reviewerService: ReviewerService,
    private userService: UserService,
    private elRef:ElementRef,
    private messagesService: MessagesService,
    private storageService: StorageService,
    private toastService: ToastService,
    private emitterService: EmittersService,
  ) { 
    this.tenantSubscriber = this.emitterService.tenantEmitter.subscribe(()=>{
      this.resetView();
      this.ngOnInit()
    });
    
  }

  resetView(){
    this.roles = [];
    this.positions = [];
    this.discussions = [];
    this.isMessageOpen = false;
    this.messages = [];
    this.roleDiscussions=[];
    this.projectBroadcastDiscussions=[];
    this.unsubDiscussionsAndThreads();
  }
  async ngOnInit() {
    await this.getReviewer();
    this.firebaseMessagingService.initFirebase();
    this.initObject();
    this.observeDiscussionsAndUpdateView();
    this.getPositions().then((positions: any) => {
      const roles: any = [];
      this.positions = [...positions.occupied, ...positions.ended];

      if(this.positions && this.positions.length){
        this.positions.forEach((position: any) => {
          roles.push({...position.role, projectName: position.project.codeName});
        });
      }

      if (this.positions.length) {
        let pos = this.storageService.getLocalStorageItem('selectedPosition_onlyReviewerMessageScreen');
        pos = pos ? pos: this.positions[0];
        this.roleId = pos.role._id || pos.role;

        // From Reviewer Dashboard To Do List
        if (history.state?.data?.roleId) {
          this.roleId = history.state.data?.roleId;
          pos = this.positions.find((p: any) => p.role._id === this.roleId);          
        }

        this.roles =  roles.filter((role: any) => 
                        role.status !== constants.roleStatus.archive && !role.isDeleted
                      )
                      .map((role: any) => {
                        return {roleType: role.roleType, _id: role._id};
                      });


        this.fetchAndSetRoles(pos);
      }
    });
    if (window.innerWidth <= 824) {
      this.isMobile=true;
    }
  }

  async getReviewer(){
   const reviewer = await lastValueFrom(this.reviewerService.fetchReviewer());
   this.reviewer = reviewer;
  }

  initObject(){
    this.userInfo = {
      currentUserId: this.userService.getSessionUser()._id,
      currentSelectedDiscussion: null,
      content: '',
      roleId: null,
      projectId: null,
      firmId: null,
      projectOwnerId: null,
      editMessageId: null,
      isUpdateDiscussion: false
    };
  }

  observeDiscussionsAndUpdateView(){
    combineLatest([this.varA$, this.varB$])
    .pipe(
      // Optionally, ensure that the observable only emits when the specific variable changes
      distinctUntilChanged(([prevA, prevB], [currA, currB]) => currA === prevA && currB === prevB),

      // Optionally, focus on changes from a specific variable
      map(([a, b]) => a) 
    )
    .subscribe((a) => {
      // Do something when `a` changes
      let _discussions = this.roleDiscussions.concat(this.projectBroadcastDiscussions);
      _discussions = _discussions.sort((a:any, b:any) => b.lastMessageDate - a.lastMessageDate);
      this.discussions = _discussions.sort((a:any, b:any) => b.reviewerUnreadMessageCount - a.reviewerUnreadMessageCount);
      // Perform any actions or update your view
      if(!this.messages.length && this.discussions.length > 0){
          this.getThreadsByDiscussion(this.discussions[0].discusstionId, this.discussions[0].createdBy);
      }
    });
  }

  async getPositions() {
    return await lastValueFrom(this.reviewerService.getPositions());
  }

  sortArrayByOrder(arrayToSort: any[], orderArray: any[]): any[] {
    return arrayToSort.sort((a, b) => {
      // Get the indices of the items in the orderArray
      const indexA = orderArray.indexOf((order:any)=>{return order.discusstionId === a.disscustionId});
      const indexB = orderArray.indexOf((order:any)=>{return order.discusstionId === b.disscustionId});
      
      // Handle cases where the item is not in the orderArray (they can go at the end)
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
  
      // Sort based on the index in orderArray
      return indexA - indexB;
    });
  }
 
  getFilteredDiscussion() {
    if (!this.subjectSearch) {
      return this.discussions;
    }
    let result: any = [];
    this.discussions.forEach((value: any) => {
      if (value.subject.toLowerCase().includes(this.subjectSearch.toLowerCase())) {
        result.push(value);
      }
    });
    return result;
  }

  ngOnDestroy(): void {
    this.unsubDiscussionsAndThreads();
    this.tenantSubscriber.unsubscribe();
  }

  unsubDiscussionsAndThreads(){
    if (this.discussionSubscriber.length) {
      this.discussionSubscriber.forEach((e:any) => e.unsubscribe())
    }
    if (this.messagesSubscriber.length) {
      this.messagesSubscriber.forEach((e:any) => e.unsubscribe())
    }
  }

  fetchMessagesByRole(){
    let rolePosition:any = {};
    
    rolePosition = this.positions.find((pos:any) => {
      return pos.role._id === this.selectedRole._id;
    })
    if(rolePosition){ 
      this.storageService.setLocalStorageItem('selectedPosition_onlyReviewerMessageScreen', rolePosition);
      this.getReviewerUnReadMessages(rolePosition.role.firm);
      this.getDiscussions(rolePosition.firm._id, rolePosition.project._id, rolePosition.role._id, rolePosition.reviewer._id ? rolePosition.reviewer._id:rolePosition.reviewer);
    }else{
      this.toastService.showError("Future role does not have any Messages.");
    }
  }

  fetchAndSetRoles(pos: any){
    this.selectedRole = this.roles.find((role:any)=>{ return role._id === pos.role._id});
    this.getDiscussions(pos.firm._id, pos.project._id, pos.role._id, pos.reviewer);
  }

  getDiscussions(firmId: string, projectId: string, roleId: string, reviewerId: string) {
    this.unsubDiscussionsAndThreads();
    this.userInfo.projectId = projectId;
    this.userInfo.roleId = roleId;
    this.userInfo.firmId = firmId;
    this.discussions = [];
    this.messages = [];
    
    this.fetchingDiscussions = true;

    this.discussionSubscriber.push(this.firebaseMessagingService.getFreelancerDiscussions(projectId, roleId, reviewerId).pipe(map(snapshot => {
      if (snapshot) {

        let discussions = Object.values(snapshot);

        discussions = discussions.sort((a:any, b:any) => b.lastMessageDate - a.lastMessageDate);
        
        this.roleDiscussions = discussions//.sort((a:any, b:any) => b.reviewerUnreadMessageCount - a.reviewerUnreadMessageCount);
        this.updateVarA();
      }
     
      this.fetchingDiscussions = false;
      
    })).subscribe({
      error: (error: any) => {
        console.error('Error loading discussions', error);
      },
      complete: () => {}
    }));

    this.discussionSubscriber.push(this.firebaseMessagingService.getProjectBroadCastDiscussions(projectId, reviewerId).pipe(
      map(snapshot => {
        
        if (snapshot) {
          let arr = Object.values(snapshot);
          
          let discussions = arr.sort((a:any, b:any) => b.lastMessageDate - a.lastMessageDate);
          this.projectBroadcastDiscussions = discussions//.sort((a:any, b:any) => b.reviewerUnreadMessageCount - a.reviewerUnreadMessageCount);
          this.updateVarB();
        } 
      })
    ).subscribe({
      error: (error) => {
        console.error('Error loading discussions', error);
      }
    }));
  }

  getThreadsByDiscussion(discussionId: string, createdBy: string) {

    this.fetchingThreads = true;
    this.messages = [];
    
    this.userInfo.currentSelectedDiscussion = discussionId;
    this.userInfo.projectOwnerId = createdBy;

    this.firebaseMessagingService.updateLastViewReviewer(discussionId);
    this.messagesSubscriber.push(this.firebaseMessagingService.getThreadsByDiscussion(discussionId).pipe(map(snapshot => {
      if (snapshot) {
        let temp = Object.values(snapshot);

        this.messages = (temp && temp[0]) ? Object.values(temp[0]!) : [];

        this.clearUnreadCount(discussionId);

        this.getReviewerUnReadMessages(this.positions.length && this.positions[0].firm._id);

        this.updateMessageTodoListItem();

        this.scrollThreadDiscussionToBottom()

        const firmMsgs = this.messages.filter((e: any) => e.sender === "FIRM" && !e.isRead).sort((a:any, b:any) => b.msgDate - a.msgDate);
        if(firmMsgs && firmMsgs.length){
          this.firebaseMessagingService.updateReviewerLastReadTimeStampInDiscussion(discussionId);
        }
        this.firebaseMessagingService.updateLastViewReviewer(discussionId);
        this.fetchingDiscussions = false;
      }
    })).subscribe({
      error: (error) => {
        console.error('Error loading discussions', error);
      },
      complete: () => {}
    }))   
    this.showList = true;
  }
  
  goBackToList() {
    this.showList = false;
  }

  getReviewerUnReadMessages(firmId: any) {
    this.messagesService.getReviewerUnReadMessages(firmId).subscribe((res: any) => {
      this.totalMessageCount = res.resp && res.resp.totalMessageCount || 0;
      this.positions.forEach((pos: any) => {
        let positions = res.resp && res.resp.positions || [];
        let obj = positions.find((e: any) => { return e.id === pos._id; });
        if(obj){
          pos.unReadMessageCount = obj.unReadMessageCount;
        }
      });
    });
  }

  clearUnreadCount(discussionId: string) {
    this.discussions.forEach((item: any) => {
      if(item && item.discusstionId === discussionId){
        item.reviewerUnreadMessageCount = 0;
      }
    });
  }

  getFormattedTime(timeStamp: any) {
    return moment(new Date(timeStamp)).fromNow();
  }

  sendMessage() {
    if(this.userInfo.content && this.userInfo.content.length){
      if (!this.userInfo.editMessageId) {
        this.firebaseMessagingService.findDiscussionOrCreateMessages({
          disscussionId: this.userInfo.currentSelectedDiscussion,
          content: this.userInfo.content,
          msgDate: this.firebaseMessagingService.getFirebaseServerTimeStamp(),
          sender: 'REVIEWER',
          from: this.userInfo.currentUserId,
          to: this.userInfo.projectOwnerId,
          firm: this.userInfo.firmId,
          projectId: this.userInfo.projectId,
          roleId: this.userInfo.roleId
        });
      }
      else {
        this.firebaseMessagingService.findDiscussionOrUpdateMessages(this.userInfo.editMessageId, {
          disscussionId: this.userInfo.currentSelectedDiscussion,
          content: this.userInfo.content
        }, this.userInfo.isUpdateDiscussion);
      }
      this.resetForm();
    }
  }

  editMessage(editMessageId: string, msg: string) {
    this.userInfo.content = msg;
    this.userInfo.editMessageId = editMessageId;
    let selectedDiscussion = this.discussions.find((dis) => {
        return dis.discusstionId === this.userInfo.currentSelectedDiscussion;
    });
    this.userInfo.isUpdateDiscussion = (selectedDiscussion.lastMessage === msg && selectedDiscussion.lastMessageSender === 'REVIEWER');
  }

  deleteMessage(deleteMessageId: string, msg: string) {
    let selectedDiscussion = this.discussions.find((dis) => {
      return dis.discusstionId === this.userInfo.currentSelectedDiscussion;
    });
    this.firebaseMessagingService.findDiscussionOrDeleteMessages(deleteMessageId, {
        disscussionId: this.userInfo.currentSelectedDiscussion,
        content: msg
    }, (selectedDiscussion.lastMessage === msg && selectedDiscussion.lastMessageSender === 'REVIEWER'));
  }

  resetForm() {
    this.userInfo.editMessageId = null;
    this.userInfo.content = '';
    this.userInfo.isUpdateDiscussion = false;
  }

  updateVarA() {
    this.varA$.next(this.varA$.getValue()+1);
  }

  updateVarB() {
    this.varB$.next(this.varB$.getValue()+1);
  }  

  onEnterPressed($event: Event): void {
    $event.preventDefault();
    this.sendMessage();
  }

  updateMessageTodoListItem(){
    let query = {"subject":{"$regex": "Message Received", "$options": "i"}, role: this.roleId};
    this.reviewerService.updateNotificationHistory(query, 'complete').subscribe(() => {});
  }

  scrollThreadDiscussionToBottom() {
    setTimeout(() => {
      const element = this.threadDiscussionPanel?.contentViewChild?.nativeElement;
      if(element){
        element.scrollTop = element.scrollHeight;
      }
    }, 1000);
  }

}
