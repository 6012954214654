<div class="page">
    <app-header (toggleSideNav)="onToggleSideNav()"></app-header>
    <div class="main" [ngClass]="{ 'expanded': !isSideNavOpen}">
        <div class="sidebar">
            <app-sidenav  (toggleSideNav)="onToggleSideNav()" ></app-sidenav>
        </div>
        <div class="content">
            <div class="flex-grow-1 p-4">
                <ng-content></ng-content>
            </div>            
        </div>
    </div>
</div>